import Stats from 'components/Stats';
import Dash from 'components/base/Dash';
import Flex from 'components/base/Flex';

import useModal from 'hooks/use-modal';
import { formatETHWithSuffix, formatInteger } from 'utils/formatters';
import { isNumberType } from 'utils/helpers';

import { UserLight } from 'types/Account';

interface CollectionStatsProps {
  artworkCount: number;
  ownersCount: number;
  floorPrice: number | null;
  owners: UserLight[];
  totalSales: number | null;
}

export default function CollectionStats(props: CollectionStatsProps) {
  const { artworkCount, ownersCount, owners, floorPrice } = props;

  const { setModal } = useModal();

  return (
    <Stats.Root>
      <Stats.Wrapper>
        <Stats.Label>Collection of</Stats.Label>
        <Stats.Value
          css={{
            display: 'flex',
            gap: '$3',
            alignItems: 'center',
            '@bp2-max': {
              justifyContent: 'center',
            },
          }}
        >
          {formatInteger(artworkCount)}
        </Stats.Value>
      </Stats.Wrapper>
      <Stats.Wrapper clickable>
        <Stats.ClickableBackground
          onClick={() => setModal({ type: 'OWNED_BY' })}
        />
        <Stats.Label>Owned by</Stats.Label>
        <Flex
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            '@bp2': { justifyContent: 'flex-start' },
          }}
        >
          <Stats.Value>{formatInteger(ownersCount)}</Stats.Value>
          {owners.length > 0 && <Stats.GroupedUsers users={owners} />}
        </Flex>
      </Stats.Wrapper>
      <Stats.Wrapper>
        <Stats.Label>Floor Price</Stats.Label>
        <Stats.Value>
          {isNumberType(floorPrice) ? (
            formatETHWithSuffix(floorPrice)
          ) : (
            <Dash />
          )}
        </Stats.Value>
      </Stats.Wrapper>
      <Stats.Wrapper
        css={{
          borderTop: '1px solid $black10',
          '@bp2': { borderTop: 'none' },
        }}
      >
        <Stats.Label>Total Sales</Stats.Label>
        <Stats.Value>
          {isNumberType(props.totalSales) ? (
            formatETHWithSuffix(props.totalSales)
          ) : (
            <Dash />
          )}
        </Stats.Value>
      </Stats.Wrapper>
    </Stats.Root>
  );
}
