import { keyframes, styled } from '@f8n-frontend/stitches';

import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Grid from 'components/base/Grid';
import Heading from 'components/base/Heading';
import TransactionParagraph from 'components/transactions/TransactionParagraph';

interface DropzoneUploadProgressProps {
  uploadProgress: number;
}

export default function DropzoneUploadProgress(
  props: DropzoneUploadProgressProps
) {
  const { uploadProgress } = props;

  return (
    <Flex css={{ height: '100%', paddingBottom: '$8' }} center expandVertical>
      <Grid css={{ gap: '$8' }}>
        <Heading
          size={{ '@initial': 4, '@bp0': 6, '@bp1': 7 }}
          css={{ textAlign: 'center' }}
        >
          Uploading to IPFS…
        </Heading>

        <Box css={{ maxWidth: 640 }}>
          <ProgressBar uploadProgress={uploadProgress} />
        </Box>
        <Grid
          css={{
            gap: '$4',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <TransactionParagraph css={{ maxWidth: 480 }}>
            The InterPlanetary File System, known as IPFS, is a peer-to-peer
            network for sharing data in a distributed file system.
          </TransactionParagraph>
        </Grid>
      </Grid>
    </Flex>
  );
}

const translate = keyframes({
  '0%': { backgroundPositionX: 0 },
  '100%': { backgroundPositionX: '-150000px' },
});

const RainbowBar = styled(Box, {
  animation: `${translate} 400s linear infinite`,
  animationFillMode: 'backwards',
  transition: 'all 0.6s cubic-bezier(0.23, 1, 0.32, 1)',
  backgroundSize: '800px auto',
  backgroundColor: '$black50',
  borderRadius: '$round',
});

interface ProgressBarProps {
  uploadProgress: number;
}

// TODO: put this into its own component
export function ProgressBar(props: ProgressBarProps) {
  const { uploadProgress } = props;

  return (
    <Flex
      css={{
        height: 10,
        backgroundColor: '$black10',
        borderRadius: '$round',
        position: 'relative',
      }}
    >
      <RainbowBar style={{ width: `${uploadProgress}%` }} css={{ zIndex: 2 }} />
      <RainbowBar
        style={{ width: `${uploadProgress}%` }}
        css={{
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          filter: 'blur(8px)',
          zIndex: 1,
        }}
      />
    </Flex>
  );
}
