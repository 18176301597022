export const buildIncrementedDurationSelectOptions = (length: number) => {
  return Array.from({ length }).map((_day, index) => {
    return {
      label: index,
      value: index,
    };
  });
};

export const DAY_SELECT_OPTIONS = buildIncrementedDurationSelectOptions(8);
export const HOUR_SELECT_OPTIONS = buildIncrementedDurationSelectOptions(24);
export const MINUTE_SELECT_OPTIONS = buildIncrementedDurationSelectOptions(60);
