import type { Address } from 'viem';

import { ButtonProps } from 'components/base/Button';
import MeatballDropdown from 'components/dropdown/MeatballDropdown';
import {
  AdminOnlyCollectionMenuItem,
  AdminToolsMenuItem,
} from 'components/menu-items/AdminOnlyMenuItem';
import { ReportMenuItem } from 'components/menu-items/ReportDropdownMenuItem';
import { SelfDestructCollectionMenuItem } from 'components/menu-items/SelfDestructCollectionMenuItem';
import { isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import useCanSelfDestructCollection from 'hooks/web3/use-can-self-destruct-collection';
import { ChainId } from 'lib/chains';

import { CollectionFilter } from 'types/Collection';

interface CollectionMenuProps {
  chainId: ChainId;
  isCurrentUserAdmin: boolean;
  isOwner: boolean;
  collectionPath: string;
  contractAddress: Address;
  creatorAddress: string;
  buttonVariant?: ButtonProps['variant'];
  buttonSize?: ButtonProps['size'];
}

export default function CollectionMenu(props: CollectionMenuProps) {
  const {
    chainId,
    isCurrentUserAdmin,
    isOwner,
    creatorAddress,
    contractAddress,
    buttonVariant = 'raised',
    buttonSize = 1,
  } = props;

  const auth = useAuth();

  const canSelfDestructCollection = useCanSelfDestructCollection(
    {
      contractAddress,
      chainId,
    },
    {
      enabled: isOwner,
    }
  );

  const canReport = !isMyAddress(auth, creatorAddress);

  const hasMenuItems =
    isCurrentUserAdmin || canSelfDestructCollection.isSuccess || canReport;

  if (!hasMenuItems) {
    return null;
  }

  const collectionFilter: CollectionFilter = {
    contractAddress,
    chainId,
  };

  return (
    <MeatballDropdown buttonVariant={buttonVariant} buttonSize={buttonSize}>
      {isCurrentUserAdmin && (
        <>
          <AdminToolsMenuItem />
          <AdminOnlyCollectionMenuItem.RefreshOwner
            collection={collectionFilter}
          />
        </>
      )}
      {canSelfDestructCollection.isSuccess && (
        <SelfDestructCollectionMenuItem collection={collectionFilter} />
      )}
      {canReport && <ReportMenuItem />}
    </MeatballDropdown>
  );
}
