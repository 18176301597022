import { ArrowLeftIcon, ArrowRightIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import {
  differenceInMinutes,
  differenceInMonths,
  eachMinuteOfInterval,
  endOfDay,
  endOfMonth,
  endOfYesterday,
  format,
  fromUnixTime,
  getUnixTime,
  isBefore,
  isDate,
  isEqual,
  isFuture,
  isToday,
  set,
  startOfDay,
} from 'date-fns';
import React, { useRef } from 'react';
import {
  DayProps,
  DayPicker,
  useDayRender,
  useActiveModifiers,
  useNavigation,
  useDayPicker,
  DayPickerProvider,
  DayPickerSingleProps,
} from 'react-day-picker';
import { useMeasure } from 'react-use';

import FauxInput from 'components/FauxInput';
import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import Field from 'components/base/Field';
import Flex from 'components/base/Flex';
import Mono from 'components/base/Mono';
import Popover from 'components/base/Popover';
import Text from 'components/base/Text';

import { formatDatePickerInputDate } from 'utils/dates/dates';

/**
 * the plan is for these constants to be converted to
 * props when the date picker needs more configurability
 */
const TIME_INTERVAL = 15;
const CELL_WIDTH = 32;
const BUFFER_IN_MINS = 15;

const isValidDate = (value: unknown): value is Date => isDate(value);

type DatePickerRootProps = Omit<DatePickerContext, 'onSelect'> &
  DatePickerProps & {
    onSelect(date: Date | undefined): void;
  };

type DatePickerProps = {
  label: string | null;
  required?: boolean;
  isNowSelectable?: boolean;
  tooltip?: string;
};

type DatePickerContext = {
  selected: DayPickerSingleProps['selected'];
  onSelect: NonNullable<DayPickerSingleProps['onSelect']>;
};

export function DatePicker(props: DatePickerRootProps) {
  const {
    label,
    required = true,
    isNowSelectable = true,
    tooltip,
    ...dayPickerProps
  } = props;

  return (
    <DayPickerProvider initialProps={{ ...dayPickerProps, mode: 'single' }}>
      <DatePickerConnected
        label={label}
        required={required}
        isNowSelectable={isNowSelectable}
        tooltip={tooltip}
      />
    </DayPickerProvider>
  );
}

function DatePickerConnected(props: DatePickerProps) {
  const dayPicker = useDayPicker() as DatePickerContext;
  const selectedDay = dayPicker.selected;

  const timesOfDay = isValidDate(selectedDay)
    ? getTimeIntervals(selectedDay)
    : [];

  const [calendarRef, calendarDimensions] = useMeasure<HTMLDivElement>();

  const parentRef = useRef<HTMLDivElement>(null);

  // TODO: add comments for this logic
  const handleActiveRef = (activeTimeEl: HTMLButtonElement | null) => {
    const parentEl = parentRef.current;

    if (!activeTimeEl || !parentEl) return;

    const parentElementBounds = {
      top: parentEl.scrollTop,
      bottom: parentEl.scrollTop + calendarDimensions.height,
    };

    const scrollOffset = activeTimeEl.offsetTop - parentEl.offsetTop;

    if (
      scrollOffset < parentElementBounds.top ||
      scrollOffset > parentElementBounds.bottom
    ) {
      activeTimeEl.scrollIntoView({
        block: 'center',
      });
    }
  };

  const datePicker = (
    <DatePickerBody data-testid="date-picker">
      <div ref={calendarRef}>
        <DayPicker
          {...dayPicker}
          disabled={(date) => {
            const isBeforeYesterday = isBefore(date, endOfYesterday());
            const diffInMonths = differenceInMonths(date, Date.now());

            /**
             * eventually this will be configurable via props
             * but we want to disable dates in the past along
             * with beyond a year into the future
             */
            return isBeforeYesterday || diffInMonths > 12;
          }}
          components={{
            Day: (props) => {
              // TODO: add comments about how this logic works
              const dateTimeRange = getTimeIntervals(props.date);

              const date = dateTimeRange[0] || props.date;

              return (
                <DatePickerDay
                  date={
                    isToday(date) || !selectedDay
                      ? date
                      : mergeDateTime(date, selectedDay)
                  }
                  displayMonth={date}
                />
              );
            },
            Caption: DatePickerCaption,
            Footer: () =>
              props.isNowSelectable ? (
                <DatePickerFooter
                  setDate={dayPicker.onSelect}
                  selectedDay={selectedDay}
                />
              ) : null,
          }}
        />
      </div>

      {timesOfDay.length > 0 && (
        <TimePickerWrapper
          ref={parentRef}
          css={{ height: calendarDimensions.height }}
        >
          <TimePickerBody>
            {timesOfDay.map((timeOfDay) => {
              const isActiveDateTime = selectedDay
                ? isEqual(selectedDay, timeOfDay)
                : false;

              const dateLabel = (
                <Mono ellipsis size={1}>
                  {format(timeOfDay, 'h:mm aa')}
                </Mono>
              );

              if (isActiveDateTime) {
                return (
                  <TimeOption
                    key={timeOfDay.toISOString()}
                    ref={handleActiveRef}
                    variant="primary"
                    css={{
                      width: '100%',
                      pointerEvents: 'none',
                    }}
                  >
                    {dateLabel}
                  </TimeOption>
                );
              } else {
                return (
                  <Popover.Close key={timeOfDay.toISOString()} asChild>
                    <TimeOption
                      variant="ghost"
                      css={{
                        width: '100%',
                        pointerEvents: 'all',
                      }}
                      onClick={(ev) => {
                        dayPicker.onSelect(timeOfDay, timeOfDay, {}, ev);
                      }}
                    >
                      {dateLabel}
                    </TimeOption>
                  </Popover.Close>
                );
              }
            })}
          </TimePickerBody>
        </TimePickerWrapper>
      )}
    </DatePickerBody>
  );

  const isTimeUnset = selectedDay ? getUnixTime(selectedDay) === 0 : false;

  const getFauxInputText = () => {
    if (isTimeUnset) {
      return 'Now';
    } else if (isValidDate(selectedDay)) {
      return formatDatePickerInputDate(selectedDay);
    } else {
      return undefined;
    }
  };

  const fauxInputText = getFauxInputText();

  return (
    <Popover.Root>
      <DateContainer>
        <Field
          size={2}
          label={props.label}
          htmlFor="date-picker"
          required={props.required}
          tooltip={props.tooltip}
        >
          <FauxInput
            data-testid="date-picker-input"
            variant={!fauxInputText ? 'placeholder' : 'normal'}
          >
            <Text ellipsis>{fauxInputText || 'Not set'}</Text>
            <Popover.Trigger asChild>
              <Button size={0} id="date-picker" css={{ whiteSpace: 'nowrap' }}>
                Edit
              </Button>
            </Popover.Trigger>
          </FauxInput>
        </Field>
      </DateContainer>
      <Popover.Portal>
        <Popover.Content>{datePicker}</Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function DatePickerDay(props: DayProps) {
  const initialDate = props.date;
  const dateTime = format(initialDate, 'yyyy-MM-dd');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const activeModifiers = useActiveModifiers(initialDate);
  const dayRender = useDayRender(initialDate, props.displayMonth, buttonRef);

  return (
    <Button
      {...dayRender.buttonProps}
      ref={buttonRef}
      variant={activeModifiers.selected ? 'primary' : 'ghost'}
      style={{
        width: CELL_WIDTH,
        height: CELL_WIDTH,
        padding: 0,
        marginRight: 0,
        pointerEvents:
          activeModifiers.selected && isToday(initialDate) ? 'none' : 'all',
      }}
      css={{
        fontSize: '$1',
        fontWeight: '$medium',
        borderRadius: '$round',
      }}
    >
      <time dateTime={dateTime}>{format(props.date, 'd')}</time>
    </Button>
  );
}

function DatePickerCaption() {
  const navigation = useNavigation();

  const { disabled } = useDayPicker();

  const getDisabledState = (date: Date | undefined) =>
    typeof disabled === 'function' && isValidDate(date)
      ? disabled(endOfMonth(date))
      : false;

  return (
    <DatePickerHeader>
      <Button
        style={{ padding: 0, width: CELL_WIDTH, height: CELL_WIDTH }}
        type="button"
        variant="outline"
        disabled={getDisabledState(navigation.previousMonth)}
        onClick={() => {
          if (navigation.previousMonth) {
            navigation.goToMonth(navigation.previousMonth);
          }
        }}
      >
        <ArrowLeftIcon size={0} />
      </Button>

      <Text weight="semibold">
        {format(navigation.currentMonth, 'MMMM yyyy')}
      </Text>

      <Button
        data-testid="date-picker-next-month"
        style={{ padding: 0, width: CELL_WIDTH, height: CELL_WIDTH }}
        type="button"
        variant="outline"
        disabled={getDisabledState(navigation.nextMonth)}
        onClick={() => {
          if (navigation.nextMonth) {
            navigation.goToMonth(navigation.nextMonth);
          }
        }}
      >
        <ArrowRightIcon size={0} />
      </Button>
    </DatePickerHeader>
  );
}

type DatePickerFooterProps = {
  selectedDay: Date | undefined;
  setDate: DatePickerContext['onSelect'];
};

function DatePickerFooter(props: DatePickerFooterProps) {
  const { setDate, selectedDay } = props;
  const isChecked = selectedDay ? getUnixTime(selectedDay) === 0 : false;

  const handleCheckedChange = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!isChecked) {
      setDate(fromUnixTime(0), fromUnixTime(0), {}, ev);
    } else {
      const dateTimeRange = getTimeIntervals(new Date());

      if (dateTimeRange[0]) {
        setDate(dateTimeRange[0], dateTimeRange[0], {}, ev);
      } else {
        setDate(new Date(), new Date(), {}, ev);
      }
    }
  };

  return (
    <Tfoot>
      <tr>
        <Td colSpan={7}>
          <Flex css={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <PopoverCloseWrapper shouldClose={!isChecked}>
              <Flex
                css={{ alignItems: 'center' }}
                onClick={handleCheckedChange}
              >
                <Checkbox
                  id="checkbox"
                  onCheckedChange={() => void 0}
                  checked={isChecked}
                />
                <Text
                  size={1}
                  as="label"
                  weight="medium"
                  htmlFor="checkbox"
                  css={{ cursor: 'pointer', marginLeft: '$3' }}
                >
                  Start immediately
                </Text>
              </Flex>
            </PopoverCloseWrapper>
          </Flex>
        </Td>
      </tr>
    </Tfoot>
  );
}

type PopoverCloseWrapperProps = {
  children: React.ReactNode;
  shouldClose: boolean;
};

function PopoverCloseWrapper(props: PopoverCloseWrapperProps) {
  const { children, shouldClose } = props;
  if (shouldClose) {
    return <Popover.Close asChild>{children}</Popover.Close>;
  }
  return <>{children}</>;
}

const mergeDateTime = (date: Date, dateTimeToMerge: Date) => {
  return set(date, {
    hours: dateTimeToMerge.getHours(),
    minutes: dateTimeToMerge.getMinutes(),
    seconds: dateTimeToMerge.getSeconds(),
  });
};

const getTimeIntervals = (date: Date) => {
  return eachMinuteOfInterval(
    {
      start: startOfDay(date),
      end: endOfDay(date),
    },
    {
      step: TIME_INTERVAL,
    }
  )
    .filter((date) => {
      return isFuture(date);
    })
    .filter((date) => {
      return differenceInMinutes(date, Date.now()) >= BUFFER_IN_MINS;
    });
};

const DatePickerBody = styled('div', {
  display: 'flex',

  '@bp1': {
    alignItems: 'stretch',
  },

  '@bp1-max': {
    '>div:first-child': {
      flex: 1,
    },

    '.rdp-table': {
      width: '100%',
    },

    '.rdp-tbody > .rdp-row > .rdp-cell': {
      textAlign: 'center',
      '> button': {
        display: 'inline-block',
      },
    },
  },

  '.rdp-vhidden': {
    display: 'none',
  },

  '.rdp-table': {
    paddingBottom: '$4',
  },

  // calendar header cells
  '.rdp-head_row > .rdp-head_cell': {
    paddingBottom: '$2',
    fontSize: '$1',
    fontWeight: '$regular',
    color: '$black70',
    borderBottom: 'solid 1px $black5',
  },

  // left side of calendar body
  '.rdp-row > .rdp-cell:first-child': {
    paddingLeft: '$5',
  },

  // right side of calendar body
  '.rdp-row > .rdp-cell:last-child': {
    paddingRight: '$5',
  },

  // right side of calendar header
  '.rdp-head_row > .rdp-head_cell:last-child': {
    paddingRight: '$5',
  },

  // right side of calendar header
  '.rdp-head_row > .rdp-head_cell:first-child': {
    paddingLeft: '$5',
  },

  // top of calendar body
  '.rdp-row:first-child > .rdp-cell': {
    paddingTop: '$2',
  },

  // bottom of calendar body
  '.rdp-row:last-child > .rdp-cell': {
    paddingBottom: '$3',
  },

  // calendar body cell (excluding bottom row)
  '.rdp-tbody > .rdp-row:not(:last-child) > .rdp-cell': {
    paddingBottom: '$1',
  },

  // calendar body cell (excluding last cell)
  '.rdp-row > .rdp-cell:not(:last-child)': {
    paddingRight: '$1',
  },

  // calendar header cell (excluding last cell)
  '.rdp-head_row > .rdp-head_cell:not(:last-child)': {
    paddingRight: '$1',
  },
});

const DatePickerHeader = styled('div', {
  padding: '$3',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const TimePickerWrapper = styled('div', {
  overflow: 'auto',
  boxShadow: 'inset 1px 0 0 0 $colors$black10',
});

const TimePickerBody = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  textAlign: 'center',
  paddingBottom: '$3',
  paddingTop: '$3',
  flexShrink: 0,
  height: '100%',
});

const TimeOption = styled(Button, {
  paddingX: '$2 !important',
  paddingY: '$1 !important',
  borderRadius: 0,
  flexGrow: 0,
  maxHeight: 27,
});

const Tfoot = styled('tfoot', {
  width: '100%',
  borderTop: 'solid 1px $black5',
});

const Td = styled('td', {
  padding: '$3',
});

const DateContainer = styled('div', {
  '@hover': {
    '&:hover': {
      [`${FauxInput}`]: {
        borderColor: '$black20',
      },
    },
  },
});
