import { NotificationsIcon, AuctionIcon, CloseIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { zodResolver } from '@hookform/resolvers/zod';
import { fromUnixTime, getUnixTime } from 'date-fns';
import React, { useState } from 'react';
import { FieldPath, useForm, UseFormReturn } from 'react-hook-form';
import { match } from 'ts-pattern';
import { Address } from 'viem';
import { useWriteContract, useSimulateContract } from 'wagmi';

import FauxInput from 'components/FauxInput';
import { ListingSummary } from 'components/ListingSummary';
import { MomentTag } from 'components/MomentTag';
import TransactionModal from 'components/TransactionModal';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import Field from 'components/base/Field';
import Flex from 'components/base/Flex';
import InputV3Base from 'components/base/InputV3Base';
import Modal from 'components/base/Modal';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import Toggle from 'components/base/Toggle';
import Tooltip from 'components/base/Tooltip';
import { DatePicker } from 'components/forms/DatePicker';
import { DurationFields } from 'components/forms/DurationFields';
import { LoadingDescriptor } from 'components/transactions/LoadingDescriptor';
import CuratedStorePicker from 'components/worlds/CuratedStorePicker';
import WorldTagBase from 'components/worlds/WorldTag';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useTransactionStore, {
  useTransactionEffects,
} from 'state/stores/transactions';
import {
  BatchListVariables,
  batchListSchema,
  mapBatchListToListingSummary,
  mapBatchListToPrepareConfig,
} from 'web3/batch-list';

import { ApiListableNftFragment } from 'gql/api/api-fragments.generated';
import { useCuratedStores } from 'gql/api/queries/curated-stores.generated';
import { useQueryEffects } from 'hooks/react-query';
import useModalVisibility from 'hooks/use-modal-visibility';
import { useGenerateMomentSignatures } from 'hooks/web3/use-generate-moment-signature';
import { MAX_BATCH_LIST_COUNT, UNIX_EPOCH_DATE } from 'lib/constants';
import report from 'lib/report';
import { getAddress } from 'utils/address';
import {
  getMomentIdFromCuratedStore,
  getWorldIdFromCuratedStore,
  hasCuratedStores,
  isMomentCuratedStore,
  isWorldCuratedStore,
} from 'utils/curated-store';
import { getUnixTxDeadline } from 'utils/dates/dates';
import { formatETHWithSuffix } from 'utils/formatters';
import { isNumberType } from 'utils/helpers';
import { optimizeAsset } from 'utils/imgix';
import { extractPrepareContractWriteRevertReason } from 'utils/revert-reasons';
import { joinWithConjunction, pluralizeWord } from 'utils/strings';
import { selectTransactionByAction } from 'utils/transaction-tracking';

import { CuratedStore, CuratedStores } from 'types/CuratedStore';
import { ModalOptions } from 'types/modal';

const THUMBNAIL_WIDTH = 108;

const MODAL_KEY = 'BATCH_LIST';
const ACTION = 'batch-list';

type BatchListModalProps = {
  nfts: ApiListableNftFragment[];
  maxNftCount: number;
  fetchMoreComponent: React.ReactNode;
  refetchQuery: () => void;
};

export function BatchListModal(props: BatchListModalProps) {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && (
            <BatchListModalWindow {...props} {...modal.config} />
          )}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

function BatchListModalWindow(
  props: BatchListModalOptions & BatchListModalProps
) {
  const {
    contractAddress,
    nfts,
    maxNftCount,
    refetchQuery,
    fetchMoreComponent,
    chainId,
  } = props;

  const txStore = useTransactionStore();

  const auth = useAuth();

  const curatedStoresQuery = useCuratedStores(
    {
      creatorPublicKey: hasPublicKey(auth) ? auth.publicKey : '',
      chainId,
    },
    { enabled: hasPublicKey(auth) }
  );

  const defaultValues: BatchListVariables = {
    contractAddress: getAddress(contractAddress),
    tokenIds: [],
    shouldSetBuyPrice: false,
    buyPrice: '',
    market: {
      shouldSetReserve: true,
      reservePrice: '',
      duration: 0,
    },
    saleStartsAt: null,
  };

  const form = useForm<BatchListVariables>({
    resolver: zodResolver(batchListSchema),
    /**
     * Validation will trigger on the first blur event.
     * After that, it will trigger on every change event.
     */
    mode: 'onTouched',
    defaultValues: {
      ...defaultValues,
      tokenIds: nfts.slice(0, 50).map((nft) => nft.tokenId),
    },
  });
  const selectedTokenIds = form.watch('tokenIds');

  const reservePrice = form.watch('market.reservePrice');
  const buyPrice = form.watch('buyPrice');
  const shouldSetReserve = form.watch('market.shouldSetReserve');
  const shouldSetBuyPrice = form.watch('shouldSetBuyPrice');
  const saleStartsAt = form.watch('saleStartsAt');
  const nftCount = selectedTokenIds.length;

  const [selectedCuratedStore, setSelectedCuratedStore] =
    useState<CuratedStore | null>(null);

  const [txDeadlineUnix] = useState(() => getUnixTxDeadline());

  const batchListValues: BatchListVariables = {
    ...form.watch(),
    market: {
      ...form.watch('market'),
      reservePrice: shouldSetReserve ? reservePrice : '0',
    },
    buyPrice: shouldSetBuyPrice ? buyPrice : '0',
  };

  const [isSignaturePreparing, setIsSignaturePreparing] = useState(false);

  const parsedBatchListValues = batchListSchema.safeParse(batchListValues);

  const generateSignaturesQuery = useGenerateMomentSignatures(
    {
      contractAddress: getAddress(contractAddress),
      txDeadlineUnix,
      selectedCuratedStore,
      publicKey: hasPublicKey(auth) ? auth.publicKey : ('' as Address),
      listingType: 'NFT',
      tokenIds: selectedTokenIds,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: isSignaturePreparing && parsedBatchListValues.success,
    }
  );

  const simulateBatchList = useSimulateContract({
    ...mapBatchListToPrepareConfig({
      formValues: batchListValues,
      signatureQuery: generateSignaturesQuery,
      selectedCuratedStore,
      chainId,
    }),
  });

  useQueryEffects(simulateBatchList, {
    onError: (error) => {
      report(extractPrepareContractWriteRevertReason({ error }));
    },
  });

  const parsedError =
    extractPrepareContractWriteRevertReason(simulateBatchList);

  const getPrices = () => {
    const prices = [
      {
        label: 'reserve price',
        value: reservePrice,
        isSet: shouldSetReserve,
      },
      {
        label: 'buy now price',
        value: buyPrice,
        isSet: shouldSetBuyPrice,
      },
    ];

    return joinWithConjunction(
      prices
        .filter((price) => price.isSet)
        .map(
          (price) => `a ${price.label} of ${formatETHWithSuffix(price.value)}`
        )
    );
  };

  const contractWrite = useWriteContract({
    mutation: {
      onSuccess(txHash) {
        const pluralizedWord = pluralizeWord('NFT', nftCount);
        txStore.startTracking({
          chainId,
          ui: 'toast',
          action: {
            name: ACTION,
            buyPrice: shouldSetBuyPrice ? Number(buyPrice) : null,
            reservePrice: shouldSetReserve ? Number(reservePrice) : null,
            worldId: getWorldIdFromCuratedStore(selectedCuratedStore),
            momentId: getMomentIdFromCuratedStore(selectedCuratedStore),
            auctionDuration: shouldSetReserve
              ? Number(form.watch('market.duration'))
              : null,
            saleStartsAt: saleStartsAt
              ? fromUnixTime(saleStartsAt).toISOString()
              : new Date().toISOString(),
            nftCount: form.watch('tokenIds').length,
          },
          txHash,
          title: {
            PENDING: `Listing ${nftCount} ${pluralizedWord}…`,
            SUCCESS: 'Listing successful',
          },
          description: {
            PENDING:
              'This can take up to a couple of minutes. Feel free to close this message.',
            SUCCESS: `Your NFTs are now listed with ${getPrices()}.`,
          },
        });
      },
    },
  });

  const trackedTx = useTransactionStore(
    selectTransactionByAction({
      action: ACTION,
      txHash: contractWrite.data || null,
    })
  );

  useTransactionEffects(trackedTx, {
    onSuccess: () => refetchQuery(),
  });

  const getSubmitButton = () => {
    if (isMomentCuratedStore(selectedCuratedStore) && !isSignaturePreparing) {
      return (
        <ButtonRightAligner>
          <Button
            variant="primary"
            onClick={() => {
              setIsSignaturePreparing((bool) => !bool);
            }}
            disabled={!parsedBatchListValues.success}
          >
            Prepare listing
          </Button>
        </ButtonRightAligner>
      );
    }

    const buttonErrorMsg = parsedError;

    const buttonLabel =
      nftCount === 0
        ? 'List NFTs'
        : `List ${nftCount} ${pluralizeWord('NFT', nftCount)}`;

    if (generateSignaturesQuery.isLoading) {
      return (
        <Flex
          css={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <LoadingDescriptor
            label="Preparing your listing"
            sublabel="This should take less than a minute"
          />
          <ButtonRightAligner>
            <TransactionModal.TransactionButtonWithContractApproval
              chainId={chainId}
              write={() => {
                if (simulateBatchList.isSuccess) {
                  contractWrite.writeContract(simulateBatchList.data.request);
                }
              }}
              isDisabled={!simulateBatchList.isSuccess}
              isLoading={contractWrite.isPending || Boolean(contractWrite.data)}
              onApprovalSuccess={simulateBatchList.refetch}
              contractAddress={getAddress(contractAddress)}
              error={buttonErrorMsg}
              label={buttonLabel}
            />
          </ButtonRightAligner>
        </Flex>
      );
    }

    return (
      <ButtonRightAligner>
        <TransactionModal.TransactionButtonWithContractApproval
          chainId={chainId}
          write={() => {
            if (simulateBatchList.isSuccess) {
              contractWrite.writeContract(simulateBatchList.data.request);
            }
          }}
          isDisabled={!simulateBatchList.isSuccess}
          isLoading={contractWrite.isPending || Boolean(contractWrite.data)}
          onApprovalSuccess={simulateBatchList.refetch}
          contractAddress={getAddress(contractAddress)}
          error={buttonErrorMsg}
          label={buttonLabel}
        />
      </ButtonRightAligner>
    );
  };

  if (isMomentCuratedStore(selectedCuratedStore) && isSignaturePreparing) {
    if (generateSignaturesQuery.isLoading) {
      return (
        <TransactionModal
          chainId={chainId}
          preSignPrompt={{
            type: 'skip',
          }}
        >
          <TransactionModal.Content
            action={ACTION}
            chainId={chainId}
            txHash={contractWrite.data || null}
            header={<Modal.Header title="Preparing your listing…" />}
            footer={<Modal.Footer>{getSubmitButton()}</Modal.Footer>}
          >
            <TransactionModal.Body
              css={{
                paddingBottom: '$8',
              }}
            >
              <Text size={2} weight="medium">
                Listing {nftCount} {pluralizeWord('work', nftCount)}
              </Text>
              {parsedBatchListValues.success && (
                <ListingSummary
                  {...mapBatchListToListingSummary({
                    formValues: parsedBatchListValues.data,
                    curatedStore: selectedCuratedStore,
                  })}
                />
              )}
            </TransactionModal.Body>
          </TransactionModal.Content>
        </TransactionModal>
      );
    } else {
      return (
        <TransactionModal
          chainId={chainId}
          preSignPrompt={{
            type: 'skip',
          }}
        >
          <TransactionModal.Content
            action={ACTION}
            chainId={chainId}
            txHash={contractWrite.data || null}
            header={
              <Modal.Header
                title="Confirm your listing"
                onBackClick={() => {
                  setIsSignaturePreparing(false);
                }}
              />
            }
            footer={<Modal.Footer>{getSubmitButton()}</Modal.Footer>}
          >
            <TransactionModal.Body
              css={{
                paddingBottom: '$8',
              }}
            >
              <Text size={2} weight="medium">
                Listing {nftCount} {pluralizeWord('work', nftCount)}
              </Text>
              {parsedBatchListValues.success && (
                <ListingSummary
                  {...mapBatchListToListingSummary({
                    formValues: parsedBatchListValues.data,
                    curatedStore: selectedCuratedStore,
                  })}
                />
              )}
            </TransactionModal.Body>
          </TransactionModal.Content>
        </TransactionModal>
      );
    }
  }

  return (
    <TransactionModal
      chainId={chainId}
      preSignPrompt={{
        type: 'skip',
      }}
    >
      <TransactionModal.Content
        action={ACTION}
        chainId={chainId}
        maxWidth={840}
        size={0}
        txHash={contractWrite.data || null}
        header={<Modal.Header title="Set your price" />}
        footer={<Modal.Footer>{getSubmitButton()}</Modal.Footer>}
      >
        <TransactionModal.Body>
          <BatchListContent
            fetchMoreComponent={fetchMoreComponent}
            maxNftCount={maxNftCount}
            form={form}
            nfts={nfts}
            setSelectedCuratedStore={setSelectedCuratedStore}
            selectedCuratedStore={selectedCuratedStore}
            curatedStores={
              curatedStoresQuery.isSuccess
                ? curatedStoresQuery.data.curatedStores
                : { worlds: [], moments: [] }
            }
          />
        </TransactionModal.Body>
      </TransactionModal.Content>
    </TransactionModal>
  );
}

type BatchListModalOptions = ModalOptions<typeof MODAL_KEY>;

type BatchListContentProps = {
  form: UseFormReturn<BatchListVariables>;
  nfts: ApiListableNftFragment[];
  maxNftCount: number;
  fetchMoreComponent: React.ReactNode;
  curatedStores: CuratedStores;
  selectedCuratedStore: CuratedStore | null;
  setSelectedCuratedStore: React.Dispatch<
    React.SetStateAction<CuratedStore | null>
  >;
};

function BatchListContent(props: BatchListContentProps) {
  const {
    form,
    nfts,
    fetchMoreComponent,
    maxNftCount,
    selectedCuratedStore,
    setSelectedCuratedStore,
    curatedStores,
  } = props;

  const selectedTokenIds = form.watch('tokenIds');

  const setSelected = (selectedTokenIds: number[]) => {
    form.setValue('tokenIds', selectedTokenIds);
  };

  const AVAILABLE_MAX =
    nfts.length <= MAX_BATCH_LIST_COUNT ? nfts.length : MAX_BATCH_LIST_COUNT;

  const saleStartsAt = form.watch('saleStartsAt');

  const getWorldField = () => {
    if (!hasCuratedStores(curatedStores)) {
      return null;
    }

    if (selectedCuratedStore) {
      return (
        <Field label="Gallery">
          <Option isValid>
            {match(selectedCuratedStore)
              .when(isWorldCuratedStore, ({ world }) => (
                <WorldTagBase type="static" world={world} showCuratorFee />
              ))
              .when(isMomentCuratedStore, ({ moment }) => (
                <MomentTag type="static" moment={moment} world={moment.world} />
              ))
              .exhaustive()}
            <Button
              variant="ghost"
              icon="standalone"
              aria-label="Remove gallery"
              css={{ color: '$black50' }}
              onClick={() => {
                setSelectedCuratedStore(null);
                form.trigger();
              }}
              type="button"
            >
              <CloseIcon />
            </Button>
          </Option>
        </Field>
      );
    }

    return (
      <Field htmlFor="curated-store-picker" label="Launch with a curator">
        <Box
          css={{
            [`& ${FauxInput}`]: {
              borderRadius: '$3',
              height: '$formElement1',
            },
          }}
        >
          <CuratedStorePicker
            id="curated-store-picker"
            selectedCuratedStore={null}
            selectCuratedStore={setSelectedCuratedStore}
            curatedStores={curatedStores}
          />
        </Box>
      </Field>
    );
  };

  return (
    <Flex>
      <WideColumn>
        <WideColumnHeader>
          <SelectAllLabel as="label" htmlFor="select-all">
            <Checkbox
              checked={
                selectedTokenIds.length === AVAILABLE_MAX
                  ? true
                  : selectedTokenIds.length > 0
                    ? 'indeterminate'
                    : false
              }
              id="select-all"
              onCheckedChange={() => {
                if (selectedTokenIds.length === AVAILABLE_MAX) {
                  setSelected([]);
                } else {
                  setSelected(nfts.slice(0, 50).map((image) => image.tokenId));
                }
              }}
            />
            <Text size={1} weight="medium" color="strong">
              Select max{' '}
              <Text as="span" color="dim">
                ({AVAILABLE_MAX})
              </Text>
            </Text>
          </SelectAllLabel>
          <Text size={1} weight="medium" color="strong">
            {selectedTokenIds.length}/{maxNftCount}
          </Text>
        </WideColumnHeader>

        <Grid checked={selectedTokenIds.length > 0}>
          {nfts.map((image) => {
            const isDisabled = Boolean(
              !selectedTokenIds.includes(image.tokenId) &&
                selectedTokenIds.length === MAX_BATCH_LIST_COUNT
            );

            return (
              <Relative key={image.id}>
                {isDisabled && (
                  <Tooltip content="Batch limit reached">
                    <Box
                      css={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        cursor: 'not-allowed',
                      }}
                    />
                  </Tooltip>
                )}
                <GridItem
                  aria-disabled={isDisabled}
                  checked={selectedTokenIds.includes(image.tokenId)}
                  htmlFor={image.tokenId.toString()}
                >
                  {image.assetUrl ? (
                    <Box css={{ aspectRatio: '1/1' }}>
                      <GridItemImage
                        alt=""
                        src={optimizeAsset(image.assetUrl, {
                          w: THUMBNAIL_WIDTH,
                          h: THUMBNAIL_WIDTH,
                          q: 50,
                          dpr: 2,
                          fit: 'crop',
                        })}
                        draggable={false}
                      />
                    </Box>
                  ) : (
                    <Skeleton.Block css={{ aspectRatio: '1/1' }} />
                  )}
                  <PinTR spaced>
                    <Checkbox
                      onCheckedChange={() => {
                        if (selectedTokenIds.includes(image.tokenId)) {
                          form.setValue(
                            'tokenIds',
                            selectedTokenIds.filter(
                              (item) => item !== image.tokenId
                            )
                          );
                        } else {
                          form.setValue('tokenIds', [
                            ...selectedTokenIds,
                            image.tokenId,
                          ]);
                        }
                      }}
                      checked={selectedTokenIds.includes(image.tokenId)}
                      id={image.tokenId.toString()}
                    />
                  </PinTR>
                </GridItem>
              </Relative>
            );
          })}
        </Grid>
        {fetchMoreComponent}
      </WideColumn>

      <ShortColumn>
        <FormWrapper>
          {getWorldField()}
          <ConnectedField
            form={form}
            name="market.reservePrice"
            isToggled={form.watch('market.shouldSetReserve')}
            onToggle={(toggled) =>
              form.setValue('market.shouldSetReserve', toggled)
            }
            label={
              <Flex css={{ gap: '$2', alignItems: 'center' }}>
                <AuctionIcon />
                Reserve price
              </Flex>
            }
          />

          {form.watch('market.shouldSetReserve') && (
            <Box
              css={{
                [`& ${Button}`]: { fontSize: '0.75rem' },
              }}
            >
              <DurationFields
                size={0}
                activeDurationInSeconds={form.watch('market.duration')}
                onChange={(seconds) =>
                  form.setValue('market.duration', seconds)
                }
              />
            </Box>
          )}

          <ConnectedField
            form={form}
            name="buyPrice"
            isToggled={form.watch('shouldSetBuyPrice')}
            onToggle={(toggled) => form.setValue('shouldSetBuyPrice', toggled)}
            label={
              <Flex css={{ gap: '$2', alignItems: 'center' }}>
                <NotificationsIcon />
                Buy Now price
              </Flex>
            }
          />
          <Box
            css={{
              [`& ${FauxInput}`]: {
                borderRadius: '$3',
                height: '$formElement1',
              },
            }}
          >
            <DatePicker
              required
              label="Becomes available"
              selected={
                isNumberType(saleStartsAt)
                  ? fromUnixTime(saleStartsAt)
                  : UNIX_EPOCH_DATE // used to force DatePicker to display Now by default
              }
              onSelect={(date) => {
                if (!date) {
                  return form.setValue('saleStartsAt', null, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }

                // Note that when `immediately` is selected, the date is set to the Unix epoch (Jan 1st 1970).
                const unixTime = getUnixTime(date);

                if (unixTime === 0) {
                  return form.setValue('saleStartsAt', null, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                } else {
                  form.setValue('saleStartsAt', unixTime, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }
              }}
            />
          </Box>
        </FormWrapper>
      </ShortColumn>
    </Flex>
  );
}

type ConnectedFieldProps = {
  label: React.ReactNode;
  isToggled: boolean;
  form: UseFormReturn<BatchListVariables>;
  name: FieldPath<BatchListVariables>;
  onToggle(pressed: boolean): void;
};

function ConnectedField(props: ConnectedFieldProps) {
  const { form, isToggled, name, label, onToggle } = props;

  const fieldState = form.getFieldState(name);
  const toggleId = `${name}-toggle`;

  if (!isToggled) {
    return (
      <Relative>
        <Field htmlFor={toggleId} label={label} required size={1} mono>
          <></>
        </Field>

        <PinTR spaced={false}>
          <Toggle
            id={toggleId}
            onPressedChange={onToggle}
            pressed={isToggled}
            size={0}
          />
        </PinTR>
      </Relative>
    );
  }

  return (
    <Relative>
      <Field
        error={fieldState.error ? fieldState.error.message : undefined}
        touched={fieldState.isTouched}
        htmlFor={name}
        label={label}
        required
        size={1}
        mono
      >
        <InputV3Base
          {...form.register(name)}
          id={name}
          suffix="ETH"
          placeholder="0.00"
          autoComplete="off"
        />
      </Field>
      <PinTR spaced={false}>
        <Toggle
          id={toggleId}
          onPressedChange={onToggle}
          pressed={isToggled}
          size={0}
        />
      </PinTR>
    </Relative>
  );
}

const WideColumn = styled('div', {
  flex: 1,
  position: 'relative',
});

const WideColumnHeader = styled('div', {
  padding: '$5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  top: 0,
  zIndex: 1,
  minHeight: 'fit-content',
  position: 'sticky',
  background: '$white80',
  backdropFilter: 'blur(10px)',
  boxShadow:
    'inset 0px 1px 0px 0px $colors$black5, 0px 1px 0px 0px $colors$black5',
});

const ShortColumn = styled('div', {
  position: 'relative',

  borderLeft: '1px solid $colors$black10',
  width: '300px',
});

const SelectAllLabel = styled('label', {
  gap: '$2',
  display: 'flex',
  alignItems: 'center',
});

const FormWrapper = styled('div', {
  top: 0,
  padding: '$5',
  position: 'sticky',

  gap: '$6',
  display: 'flex',
  flexDirection: 'column',
  label: {
    fontSize: '$1',
  },
});

const ButtonRightAligner = styled('div', {
  marginLeft: 'auto',
});

const GridItemImage = styled('img', {
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover',
  borderRadius: '$2',
  transition: 'opacity $3 $ease',
});

const Grid = styled('div', {
  gap: '$4',
  padding: '$5',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  alignContent: 'flex-start',
  minHeight: 360,

  variants: {
    checked: {
      true: {},
      false: {},
    },
  },
});

const GridItem = styled('label', {
  cursor: 'pointer',
  aspectRatio: '1/1',
  borderRadius: '$2',
  // position: 'relative',
  willChange: 'transform',
  transition: 'transform $3 $ease, opacity $3 $ease',

  '&:after': {
    opacity: 0,
    content: '""',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '$2',
    transition: 'opacity $2 $ease',
    boxShadow: 'inset 0 0 0 3px $colors$black100',
  },

  '@hover': {
    '&:hover': {
      transform: 'translateY(-1px)',
    },
  },

  '&:active': {
    transform: 'translateY(2px)',
  },

  '&[aria-disabled="true"]': {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    [`${GridItemImage}`]: {
      opacity: 0.5,
    },
  },

  variants: {
    checked: {
      true: {
        '@hover': {
          '&:hover': {
            transform: 'translateY(0)',
            opacity: 0.85,
          },
        },
        '&:after': {
          opacity: 1,
        },
        [`${GridItemImage}`]: {
          opacity: 1,
        },
      },
      false: {},
    },
  },
});

const PinTR = styled('div', {
  top: '$2',
  right: '$2',
  position: 'absolute',

  variants: {
    spaced: {
      true: {
        top: '$2',
        right: '$2',
      },
      false: {
        top: '0px',
        right: '0px',
      },
    },
  },
});

const Relative = styled('div', {
  position: 'relative',
});

const Option = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
  border: '1px solid $black5',
  padding: '$3',
  borderRadius: '$3',

  '& + &': { marginTop: '$2' },

  variants: {
    isValid: {
      true: {
        borderColor: 'transparent',
        background: '$black5',
        justifyContent: 'space-between',
      },
    },
  },
});
