import MaximalLoader from 'components/MaximalLoader';
import Page from 'components/Page';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Heading from 'components/base/Heading';
import Text from 'components/base/Text';

interface CollectionNotAddedProps {
  isLoading: boolean;
}

export default function CollectionNotAdded(props: CollectionNotAddedProps) {
  const { isLoading } = props;

  if (isLoading) {
    return <MaximalLoader />;
  }

  return (
    <Page title="Nothing to see here">
      <Body css={{ display: 'flex', alignItems: 'center' }}>
        <Box css={{ maxWidth: 400, marginX: 'auto', textAlign: 'center' }}>
          <Heading size={5} css={{ marginBottom: '$5' }}>
            Nothing to see here.
          </Heading>
          <Text css={{ marginBottom: '$7' }} lineHeight={3} color="dim">
            The collection you're looking for is not yet on Foundation. If you'd
            like to see it here, let us know by submitting the form below.
          </Text>

          <Button
            as="a"
            size={2}
            href="https://withfoundation.typeform.com/to/IZo42JBF"
            target="_blank"
            css={{ display: 'inline-flex' }}
          >
            Recommend a collection
          </Button>
        </Box>
      </Body>
    </Page>
  );
}
