import { styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';
import React, { ChangeEventHandler } from 'react';

import Field, { FieldProps, FieldVariants } from './Field';
import { Input } from './InputV3Base';

export type SelectOption = {
  label: string | number;
  value: string | number;
};

type SelectInputVariants = VariantProps<typeof SelectInput>;

export type SelectProps = {
  defaultValue?: SelectOption['value'];
  name: string;
  label: string | null;
  options: SelectOption[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  size?: FieldVariants['size'];
  error?: FieldProps['error'];
  touched?: FieldProps['touched'];
  required?: FieldProps['required'];
  value?: SelectOption['value'];
  variant?: SelectInputVariants['variant'];
};

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  function Select(props, ref) {
    const {
      defaultValue,
      options,
      name,
      label,
      required = false,
      touched,
      size = 1,
      error,
      onChange,
      onBlur,
      value,
      variant,
    } = props;

    return (
      <Field
        htmlFor={name}
        label={label}
        required={required}
        size={size}
        error={error}
        touched={touched}
      >
        <SelectInput
          id={name}
          as="select"
          defaultValue={defaultValue}
          ref={ref}
          name={name}
          size={size}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          variant={variant}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectInput>
      </Field>
    );
  }
);

const SelectInput = styled(Input, {
  cursor: 'pointer',
  backgroundRepeat: 'no-repeat',

  variants: {
    size: {
      0: {
        backgroundPosition: 'right 12px top 50%',
        backgroundImage: 'url(/images/icons/chevron-small.svg)',
      },
      1: {
        backgroundPosition: 'right $space$4 top 50%',
        backgroundImage: 'url(/images/icons/chevron-medium.svg)',
      },
      2: {
        backgroundPosition: 'right $space$4 top 50%',
        backgroundImage: 'url(/images/icons/chevron-large.svg)',
      },
    },
    variant: {
      /** use when rendering a select on it's own */
      standalone: {
        // Important to override Field sizing
        borderRadius: '$3 !important',
      },
      /** use when rendering a select in a form near other inputs */
      form: {},
    },
  },

  defaultVariants: {
    variant: 'form',
  },
});
