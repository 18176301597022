import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Text from 'components/base/Text';
import { DeepLinkTabConfig } from 'components/tabs/Tabs';

import { setDeepLinkUrlParams } from 'utils/deep-link';
import { getFirstValue } from 'utils/helpers';

import { Collection } from 'types/Collection';

const COLLECTION_TABS = [
  'ACTIVITY',
  'ADMIN',
  'ARTWORKS',
  'DESCRIPTION',
] as const;

export type CollectionTabValue = (typeof COLLECTION_TABS)[number];

interface CollectionTabsOptions {
  collection: Collection;
  isCurrentUserAdmin: boolean;
}

interface CollectionTabConfig {
  enabled: boolean;
  label: DeepLinkTabConfig['label'];
}

interface CollectionTabItem extends DeepLinkTabConfig {
  value: CollectionTabValue;
}

export default function useCollectionTabs(options: CollectionTabsOptions) {
  const { collection, isCurrentUserAdmin } = options;

  const router = useRouter();
  const deepLinkTabParam = getFirstValue(router.query.tab);

  const tabsConfig: Record<CollectionTabValue, CollectionTabConfig> = {
    ARTWORKS: {
      label: <Text>NFTs</Text>,
      enabled: true,
    },
    DESCRIPTION: {
      label: <Text>Description</Text>,
      enabled: Boolean(collection?.description),
    },
    ACTIVITY: {
      label: <Text>Activity</Text>,
      enabled: true,
    },
    ADMIN: {
      label: <Text>Admin</Text>,
      enabled: isCurrentUserAdmin,
    },
  };

  const tabs = Object.entries(tabsConfig)
    .filter(([_tabValue, tab]) => tab.enabled)
    .map(
      (tab: [CollectionTabValue, CollectionTabConfig]): CollectionTabItem => {
        const [value, config] = tab;

        return {
          label: config.label,
          value,
          onClick: () => {
            setActiveTabValue(value);

            if (value === 'ADMIN' || value === 'ARTWORKS') return;

            setDeepLinkUrlParams({ tab: value.toLowerCase() });
          },
        };
      }
    );

  const [activeTabValue, setActiveTabValue] = useState<CollectionTabValue>(
    () => {
      if (tabs[0]) {
        return tabs[0].value;
      }

      return 'ARTWORKS';
    }
  );

  useEffect(() => {
    const deepLinkTab = deepLinkTabParam
      ? (deepLinkTabParam.toUpperCase() as CollectionTabValue)
      : undefined;

    if (!deepLinkTab) return;

    if (
      COLLECTION_TABS.includes(deepLinkTab) &&
      deepLinkTab !== activeTabValue
    ) {
      setActiveTabValue(deepLinkTab);
    }
  }, [router.isReady]);

  return {
    tabs,
    activeTabValue,
  };
}
