import { styled } from '@f8n-frontend/stitches';

import Text from 'components/base/Text';

const TransactionParagraph = styled(Text);

TransactionParagraph.defaultProps = {
  color: 'dim',
  size: { '@initial': 1, '@bp2': 2 },
  lineHeight: 3,
};

export default TransactionParagraph;
