import { cond, equals, always, T } from 'ramda';

import Page from 'components/Page';

import { Collection } from 'types/Collection';
import { ModerationStatus } from 'types/Moderation';

import { CollectionModeration } from './CollectionModeration';

interface CollectionWarningBlockProps {
  collection: Pick<Collection, 'moderationStatus' | 'moderationFrom'>;
}

export default function CollectionWarningBlock(
  props: CollectionWarningBlockProps
) {
  const { collection } = props;

  return cond<ModerationStatus, JSX.Element | null>([
    [
      (status) => equals('SUSPENDED', status),
      always(
        <Page title="Collection Suspended" noFooter>
          <CollectionModeration.Suspended />
        </Page>
      ),
    ],
    [
      (status) => equals('TAKEDOWN_REQUESTED', status),
      () => (
        <Page title="DMCA Takedown Notice" noFooter>
          <CollectionModeration.TakedownRequested
            moderationFrom={collection.moderationFrom}
          />
        </Page>
      ),
    ],
    [
      (status) => equals('UNDER_REVIEW', status),
      always(
        <Page title="Collection Under Review" noFooter>
          <CollectionModeration.UnderReview />
        </Page>
      ),
    ],
    [T, () => null],
  ])(collection.moderationStatus);
}
