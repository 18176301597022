import { styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import Box from 'components/base/Box';

import { UserLight } from 'types/Account';

import Text from './base/Text';
import { MemoizedAvatarStack } from './profiles/ProfileCollectors';

const Label = styled(Text, {
  marginBottom: '$2',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  transition: 'color $1 $ease',
  '@bp2': {
    textAlign: 'left',
  },
});
Label.defaultProps = {
  size: 2,
  color: 'dim',
  weight: 'semibold',
};

const Wrapper = styled('div', {
  position: 'relative',
  paddingY: '$5',
  paddingX: '$6',
  '@bp2': {
    paddingY: '$7',
    paddingRight: '$7',
    paddingLeft: 0,
  },
  '&:first-of-type': {
    borderRight: '1px solid $black10',
    '@bp2': { borderRight: 'none' },
  },
  '&:nth-of-type(3)': {
    borderRight: '1px solid $black10',
    borderTop: '1px solid $black10',
    '@bp2': { borderRight: 'none', borderTop: 'none' },
  },
  '&:nth-of-type(4)': {
    borderTop: '1px solid $black10',
    '@bp2': { borderTop: 'none' },
  },

  variants: {
    clickable: {
      true: {
        '@hover': {
          [`&:hover ${Label}`]: {
            color: '$black100',
          },
        },
      },
      false: {},
    },
  },
});

const Value = styled(Text, {
  textAlign: 'center',
  '@bp2': {
    textAlign: 'left',
  },
});

Value.defaultProps = {
  size: {
    '@bpxs': 3,
    '@initial': 4,
  },
  weight: 'semibold',
};

type GroupedUsersProps = { users: UserLight[] };

function GroupedUsers(props: GroupedUsersProps) {
  return (
    <Box
      css={{
        marginLeft: '$3',
        display: ' none',
        position: 'relative',
        zIndex: 2,
        '@bp2': {
          display: 'block',
        },
      }}
    >
      <MemoizedAvatarStack
        size={2}
        placement="bottom"
        users={props.users}
        variant="raised"
      />
    </Box>
  );
}

type RootProps = { children: ReactNode };

function Root(props: RootProps) {
  return (
    <Box
      css={{
        display: 'inline-grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        width: 'auto',
        backgroundColor: '$white100',
        borderRadius: '$4',
        boxShadow: '$regular0',
        marginX: 'auto',
        '@bp2': {
          display: 'flex',
          marginX: 'unset',
          paddingX: '$7',
          [`& ${Wrapper}:last-child`]: {
            paddingRight: 0,
          },
        },
      }}
    >
      {props.children}
    </Box>
  );
}

/**
 * Use this if the Stats block also contains other clickable items as children, like Avatars, or other links.
 */
const ClickableBackground = styled('div', {
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const Stats = {
  ClickableBackground,
  Root,
  Wrapper,
  Label,
  Value,
  GroupedUsers,
};

export default Stats;
