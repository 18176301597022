import { addYears, getUnixTime } from 'date-fns';
import { z } from 'zod';

import { isNumberType } from 'utils/helpers';

import { VALID_PRICE } from './price';
import { COMMON_SCHEMA_FIELDS } from './shared';

export const sellViaBuyNowFormSchema = z.object({
  price: VALID_PRICE,
});

export const sellViaAuctionFormSchema = z.object({
  duration: COMMON_SCHEMA_FIELDS.AUCTION_DURATION,
  reservePrice: VALID_PRICE,
});

export const saleStartsAtSchema = z
  .number({ required_error: 'Start time must be set' })
  .int()
  .nonnegative()
  .superRefine((input, ctx) => {
    if (isNumberType(input)) {
      const now = new Date();
      const min = getUnixTime(now);
      const max = getUnixTime(addYears(now, 1));

      if (input < min) {
        return ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          inclusive: false,
          message: 'Start time must be in the future',
          minimum: min,
          type: 'number',
        });
      }

      if (input > max) {
        return ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          inclusive: false,
          message: 'Start time must be within 1 year',
          maximum: max,
          type: 'number',
        });
      }
    }
  });

export const GENERIC_PRICE_ERROR_PATH = 'price';

export const listSchema = z
  .object({
    auction: sellViaAuctionFormSchema.nullable(),
    buyNow: sellViaBuyNowFormSchema.nullable(),
    saleStartsAt: saleStartsAtSchema.nullable(),
    world: z.object({ id: COMMON_SCHEMA_FIELDS.WORLD_ID }).nullable(),
  })
  .superRefine((input, ctx) => {
    if (!input.auction && !input.buyNow) {
      if (input.world) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Cannot be listed in a gallery without a price set',
          path: [GENERIC_PRICE_ERROR_PATH],
        });
      } else {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please add a Buy Now or Reserve price',
          path: [GENERIC_PRICE_ERROR_PATH],
        });
      }
    }
  });

export const unlistSchema = z.object({
  auction: z.null(),
  buyNow: z.null(),
  world: z.null(),
});
