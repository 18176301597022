import { styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import Flex from 'components/base/Flex';
import Text from 'components/base/Text';

import { formatInteger } from 'utils/formatters';

import TabBar from './TabBar';
import TabHeading from './TabHeading';

export type DeepLinkTabConfig = {
  count?: number;
  label: ReactNode;
  value: string;
  onClick(): void;
};

interface DeepLinkTabsProps {
  activeValue: string;
  tabs: DeepLinkTabConfig[];
}

export function DeepLinkTabs(props: DeepLinkTabsProps) {
  const { activeValue, tabs } = props;

  return (
    <TabBar isScrollable>
      <TabContainer>
        {tabs.map((tab) => (
          <TabHeading
            weight="semibold"
            css={{
              '&:last-of-type': {
                marginRight: '$6',
              },
            }}
            size={{
              '@initial': 2,
              '@bp0': 3,
            }}
            key={tab.value}
            isActive={tab.value === activeValue}
            onClick={() => {
              tab.onClick();
            }}
            // hack for styling. Remove with new Tabs component
            data-value={tab.value}
          >
            {tab.label}
            {tab.count !== undefined && (
              <Text
                as="span"
                css={{ marginLeft: '$3', fontWeight: '$regular' }}
              >
                {formatInteger(tab.count)}
              </Text>
            )}
          </TabHeading>
        ))}
      </TabContainer>
    </TabBar>
  );
}

/**
 * @deprecated - use Tabs.List instead
 */
const TabContainer = styled(Flex, {
  overflowX: 'scroll',
  overflowY: 'hidden',
  maxWidth: '90vw',
  '@bp0': {
    overflow: 'hidden',
  },
});
