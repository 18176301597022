import { useRef } from 'react';

import Flex from 'components/base/Flex';
import { Select, SelectProps } from 'components/base/Select';

import {
  DAY_SELECT_OPTIONS,
  HOUR_SELECT_OPTIONS,
  MINUTE_SELECT_OPTIONS,
} from 'utils/duration-selection';
import { pluralizeWord } from 'utils/strings';
import {
  mapDurationBreakdownToSeconds,
  mapSecondsToDurationBreakdown,
} from 'utils/time';

type OnValidChange = (durationInSeconds: number) => void;

/**
 * When choosing a duration, we expose three related select inputs.
 * This hook captures the value from each input and calculates the total duration in seconds.
 */
const useRelatedDurationSelectInputs = (options: {
  onValidChange: OnValidChange;
}) => {
  const dayRef = useRef<HTMLSelectElement>(null);
  const hourRef = useRef<HTMLSelectElement>(null);
  const minuteRef = useRef<HTMLSelectElement>(null);

  const handleCustomDurationChange = () => {
    // If any of the refs are null, we can't calculate the duration.
    if (!dayRef.current || !hourRef.current || !minuteRef.current) {
      return;
    }

    const dayValue = dayRef.current.value;
    const hourValue = hourRef.current.value;
    const minuteValue = minuteRef.current.value;

    if (!dayValue || !hourValue || !minuteValue) {
      return;
    }

    const days = parseInt(dayValue);
    const hours = parseInt(hourValue);
    const minutes = parseInt(minuteValue);

    const durationInSeconds = mapDurationBreakdownToSeconds({
      days,
      hours,
      minutes,
    });

    options.onValidChange(durationInSeconds);
  };

  return {
    dayRef,
    hourRef,
    minuteRef,
    handleCustomDurationChange,
  };
};

const getDayOptions = (variant: DurationPickerVariant) => {
  if (variant === 'minimal') {
    return DAY_SELECT_OPTIONS.map((option, index) => ({
      value: option.value,
      /**
       * renders 0 days, 1 day, 2 days, etc.
       */
      label: `${option.value} ${pluralizeWord('day', index)}`,
    }));
  }
  return DAY_SELECT_OPTIONS;
};

const getHourOptions = (variant: DurationPickerVariant) => {
  if (variant === 'minimal') {
    return HOUR_SELECT_OPTIONS.map((option, index) => ({
      value: option.value,
      /**
       * renders 0 hrs, 1 hr, 2 hrs, etc.
       */
      label: `${option.value} ${pluralizeWord('hr', index)}`,
    }));
  }
  return HOUR_SELECT_OPTIONS;
};

const getMinuteOptions = (variant: DurationPickerVariant) => {
  if (variant === 'minimal') {
    return MINUTE_SELECT_OPTIONS.map((option, index) => ({
      value: option.value,
      /**
       * renders 0 mins, 1 min, 2 mins, etc.
       */
      label: `${option.value} ${pluralizeWord('min', index)}`,
    }));
  }
  return MINUTE_SELECT_OPTIONS;
};

export type DurationPickerVariant = 'minimal' | 'default';

type DurationPickerProps = {
  defaultDurationInSeconds?: number;
  onValidChange: OnValidChange;
  variant?: DurationPickerVariant;
  size?: SelectProps['size'];
};

export default function DurationPicker(props: DurationPickerProps) {
  const {
    defaultDurationInSeconds = 0,
    onValidChange,
    size = 1,
    variant = 'default',
  } = props;

  const controller = useRelatedDurationSelectInputs({
    onValidChange,
  });

  const defaultDurationBreakdown = mapSecondsToDurationBreakdown(
    defaultDurationInSeconds
  );

  return (
    <Flex css={{ gap: '$2' }}>
      <Select
        ref={controller.dayRef}
        defaultValue={defaultDurationBreakdown.days}
        name="days"
        size={size}
        onChange={controller.handleCustomDurationChange}
        options={getDayOptions(variant)}
        label={variant === 'minimal' ? null : 'Days'}
        required
      />
      <Select
        ref={controller.hourRef}
        defaultValue={defaultDurationBreakdown.hours}
        name="hours"
        size={size}
        onChange={controller.handleCustomDurationChange}
        options={getHourOptions(variant)}
        label={variant === 'minimal' ? null : 'Hours'}
        required
      />
      <Select
        ref={controller.minuteRef}
        defaultValue={defaultDurationBreakdown.minutes}
        name="minutes"
        size={size}
        options={getMinuteOptions(variant)}
        onChange={controller.handleCustomDurationChange}
        label={variant === 'minimal' ? null : 'Minutes'}
        required
      />
    </Flex>
  );
}
