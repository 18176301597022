import Box from 'components/base/Box';
import MarkdownText from 'components/base/MarkdownText';

interface CollectionDescriptionProps {
  children: string;
}

export default function CollectionDescription(
  props: CollectionDescriptionProps
) {
  const { children } = props;
  return (
    <Box
      css={{
        maxWidth: 640,
        minHeight: 400,
        paddingY: '$1',
        '@bp1': { paddingY: '$8', minHeight: 600 },
      }}
    >
      <MarkdownText
        css={{
          lineHeight: '$2',
          fontSize: '$3',
          '@bp0': {
            fontSize: '$4',
          },
        }}
      >
        {children}
      </MarkdownText>
    </Box>
  );
}
