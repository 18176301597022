import { styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import Background from 'components/Background';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Overlay from 'components/base/Overlay';
import ShareModal from 'components/modals/v2/ShareModal';

import type { CollectionStats as ICollectionStats } from 'gql/hasura/queries/collection-stats.generated';
import { ChainId } from 'lib/chains';
import { getChainIdOrDefault } from 'utils/chains';
import { buildCollectionPath } from 'utils/collections';

import { UserLight } from 'types/Account';
import { Collection } from 'types/Collection';

import CollectionMenu from './CollectionMenu';
import StandardCollectionStats from './StandardCollectionStats';

interface CollectionHeroContainerProps {
  coverImage?: string;
  contractSlug: string;
  children: ReactNode;
  collectionStats: ICollectionStats;
  currentUserPublicKey: string;
  creator: UserLight;
  collection: Collection;
  isOwner: boolean;
  isCurrentUserAdmin: boolean;
  isAdminOrCurrentUserOwner: boolean;
}

export default function CollectionHeroContainer(
  props: CollectionHeroContainerProps
) {
  const {
    coverImage,
    contractSlug,
    collectionStats,
    children,
    isCurrentUserAdmin,
    isAdminOrCurrentUserOwner,
    isOwner,
    collection,
    creator,
  } = props;

  const collectionPath = buildCollectionPath(collection);

  return (
    <Wrapper>
      {coverImage && (
        <Overlay
          css={{
            zIndex: -1,
            minHeight: 600,
            '@bp1': {
              minHeight: 760,
            },
          }}
        />
      )}
      <Background
        css={{
          backgroundColor: '$black5',
          minHeight: 510,
          '@bp1': {
            minHeight: 760,
          },
        }}
        style={{ backgroundImage: coverImage ? `url(${coverImage})` : '' }}
      />
      <InnerWrapper>{children}</InnerWrapper>
      <Flex
        css={{
          maxWidth: '$container',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, 50%)',
          paddingX: '$6',
          zIndex: 1,
          width: '100%',
        }}
      >
        <StandardCollectionStats
          contractSlug={contractSlug}
          initialData={collectionStats}
          isAdminOrCurrentUserOwner={isAdminOrCurrentUserOwner}
        />
        <Flex
          css={{
            gap: '$3',
            display: 'none',
            position: 'absolute',
            zIndex: 3,
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            paddingRight: '$6',
            '@bp2': {
              display: 'flex',
            },
          }}
        >
          <CollectionMenu
            chainId={collection.chainId as ChainId}
            isCurrentUserAdmin={isCurrentUserAdmin}
            isOwner={isOwner}
            collectionPath={collectionPath}
            contractAddress={collection?.contractAddress}
            creatorAddress={collection.creatorAddress}
          />
          <ShareModal
            shareType="collection"
            collection={collection}
            chainId={getChainIdOrDefault(collection)}
            creator={creator}
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled(Flex, {
  position: 'relative',
  alignItems: 'center',
  minHeight: 600,
  '@bp1': {
    minHeight: 760,
    alignItems: 'flex-end',
  },
});

const InnerWrapper = styled(Box, {
  maxWidth: '$container',
  zIndex: 1,
  paddingX: '$6',
  paddingY: '$10',
  marginX: 'auto',
  flexGrow: 1,
  '@bp0': {
    paddingY: '$11',
  },
});
