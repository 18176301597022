import Flex from 'components/base/Flex';

import SpinnerStroked from './SpinnerStroked';

export default function MaximalLoader() {
  return (
    <Flex
      css={{
        flex: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SpinnerStroked size={44} />
    </Flex>
  );
}
