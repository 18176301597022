import {
  ApiUpdateCollectionModerationFromDocument,
  ApiUpdateCollectionModerationFrom,
  ApiUpdateCollectionModerationFromVariables,
} from 'gql/api/mutations/update-collection-moderation-from.generated';
import {
  ApiUpdateCollectionModerationStatusDocument,
  ApiUpdateCollectionModerationStatusVariables,
} from 'gql/api/mutations/update-collection-moderation-status.generated';
import { ApiUpdateCollection } from 'gql/api/mutations/update-collection.generated';
import { fndApiClient } from 'lib/clients/graphql';
import { isStringType } from 'utils/helpers';

import { ModerationFrom, ModerationStatus } from 'types/Moderation';

export interface SetCollectionModerationProxyVariables {
  id: string;
  moderationStatus: ModerationStatus;
  moderationFrom: ModerationFrom;
  adminAddress: string;
  url: string;
}

export async function setCollectionModerationProxy({
  id,
  moderationStatus,
  moderationFrom,
  adminAddress,
  url,
}: SetCollectionModerationProxyVariables): Promise<{
  done: boolean;
}> {
  const res = await fetch('/api/admin/moderate-collection', {
    method: 'POST',
    body: JSON.stringify({
      id,
      moderationStatus,
      adminAddress,
      url,
      moderationFrom,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.ok) {
    return await res.json();
  }

  throw new Error('Failed to moderate collection');
}

interface SetCollectionModerationStatusProps {
  id: string;
  moderationStatus: ModerationStatus;
  moderationFrom: ModerationFrom;
  authToken: string;
}

export async function setCollectionModerationStatus({
  id,
  moderationStatus,
  authToken,
  moderationFrom,
}: SetCollectionModerationStatusProps) {
  const client = fndApiClient(authToken);

  if (isStringType(moderationFrom)) {
    await setCollectionModerationFrom({
      id,
      moderationFrom,
      authToken,
    });
  }

  return await client.request<
    ApiUpdateCollection,
    ApiUpdateCollectionModerationStatusVariables
  >(ApiUpdateCollectionModerationStatusDocument, { id, moderationStatus });
}

async function setCollectionModerationFrom({
  id,
  moderationFrom,
  authToken,
}: {
  id: string;
  moderationFrom: string;
  authToken: string;
}) {
  const client = fndApiClient(authToken);
  await client.request<
    ApiUpdateCollectionModerationFrom,
    ApiUpdateCollectionModerationFromVariables
  >(ApiUpdateCollectionModerationFromDocument, {
    id: id,
    moderationFrom,
  });
}
