import {
  CollectionStats as ICollectionStats,
  useCollectionStats,
} from 'gql/hasura/queries/collection-stats.generated';
import { getAggregateCount } from 'utils/aggregate';
import { getFirstValue, isNumberType, notEmptyOrNil } from 'utils/helpers';
import { nonZeroMin } from 'utils/numbers';

import { UserLight } from 'types/Account';

import CollectionStats from './CollectionStats';

type NormalizedCollectionStats = {
  artworksCount: number;
  floorPrice: number | null;
  owners: UserLight[];
  ownersCount: number;
  totalSales: number | null | undefined;
};

interface CollectionStatsProps {
  contractSlug: string;
  initialData: ICollectionStats;
  isAdminOrCurrentUserOwner: boolean;
}

export default function StandardCollectionStats(props: CollectionStatsProps) {
  const { contractSlug, initialData, isAdminOrCurrentUserOwner } = props;

  // if a user is viewing their own profile or an admin
  // is viewing it then query for non-indexed artworks too
  const indexedStates: boolean[] = isAdminOrCurrentUserOwner
    ? [true, false]
    : [true];

  const collectionStats = useCollectionStats<
    NormalizedCollectionStats | undefined
  >(
    { contractSlug: contractSlug, indexedStates },
    {
      enabled: Boolean(contractSlug),
      initialData,
      refetchOnWindowFocus: false,
      // TODO: this complex select should probably live inside this hook, not as an option.
      select: (
        data: ICollectionStats | undefined
      ): NormalizedCollectionStats | undefined => {
        const collection = getFirstValue(data?.collection);

        if (!collection) return undefined;

        const floorPrice = nonZeroMin([
          // @ts-expect-error null-checks
          collection.activeAuctions?.aggregate?.min?.reservePriceInETH,
          // @ts-expect-error null-checks
          collection.activeBuyNows?.aggregate?.min?.amountInETH,
        ]);

        // Sum all sales, stripping undefined values
        const totalSales = [
          collection.lastAcceptedOffers?.aggregate?.sum?.amountInETH,
          collection.lastAuctions?.aggregate?.sum?.highestBidAmount,
          collection.lastBuyNows?.aggregate?.sum?.amountInETH,
          collection.lastPrivateSales?.aggregate?.sum?.saleAmountInETH,
        ].reduce((prev, curr = 0) => {
          // @ts-expect-error null-checks
          return prev + curr;
        }, 0);

        const owners = collection.owners
          .flatMap((collection) => collection.owner)
          .filter(notEmptyOrNil) as NormalizedCollectionStats['owners'];

        return {
          artworksCount: getAggregateCount(collection.artworks),
          floorPrice,
          owners,
          ownersCount: getAggregateCount(collection.ownersCount),
          totalSales,
        };
      },
    }
  );

  if (collectionStats.isLoading || !collectionStats.data) {
    return null;
  }

  return (
    <CollectionStats
      artworkCount={collectionStats.data.artworksCount}
      ownersCount={collectionStats.data.ownersCount}
      floorPrice={collectionStats.data.floorPrice}
      owners={collectionStats.data.owners}
      totalSales={
        isNumberType(collectionStats.data.totalSales)
          ? collectionStats.data.totalSales
          : null
      }
    />
  );
}
