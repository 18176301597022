import {
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next';
import { match, P } from 'ts-pattern';

import Page from 'components/Page';
import ModerationBanner from 'components/admin/ModerationBanner';
import {
  StandardCollectionPage,
  StandardCollectionPageProps,
} from 'components/collections/StandardCollectionPage';
import AdminToolsModal from 'components/modals/admin-tools/AdminToolsModal';
import ChangeStatusPane from 'components/modals/admin-tools/ChangeStatusPane';
import HideCollectionPane from 'components/modals/admin-tools/HideCollectionPane';
import CollectionWarningBlock from 'components/trust-safety/CollectionWarningBlock';
import { hasPublicKey, isAdmin } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { getDropCollectionBySlug } from 'hooks/queries/api/use-drop-collection-by-slug';
import { NOT_FOUND, REVALIDATE_TIME } from 'lib/constants';
import report from 'lib/report';
import { setCollectionModerationProxy } from 'queries/admin/collection';
import { getEditionCollectionBySlug } from 'queries/api/collections';
import { getCollectionPresence } from 'queries/hasura/collection-presence';
import {
  getCollectionByContractSlug,
  getCollectionStats,
} from 'queries/hasura/collections';
import { isDropContract, isEditionContract } from 'utils/contract-type';
import { getFirstValue, isAnyTrue } from 'utils/helpers';
import { optimizeMetaImage } from 'utils/imgix';
import { isFlaggedForModeration } from 'utils/moderation';
import { getPath } from 'utils/router';
import { serverQuery } from 'utils/server';
import { areKeysEqual } from 'utils/users';

type CollectionPageProps = StandardCollectionPageProps;

export default function CollectionPageContainer(props: CollectionPageProps) {
  const { collection, type } = props;

  /**
   * there is no web2 cover image for editions
   * so instead will need to use the assetUrl
   */
  const metaImage = match(collection)
    .with({ coverImageUrl: P.select(P.string) }, (coverImageUrl) =>
      optimizeMetaImage(coverImageUrl)
    )
    .otherwise(() => undefined);

  const description = collection.description || undefined;
  const collectionName = collection.name;

  const auth = useAuth();
  const isCurrentUserAdmin = isAdmin(auth);
  const collectionModerationStatus = collection.moderationStatus;
  const isCollectionModerated = isFlaggedForModeration(
    collectionModerationStatus
  );
  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : '';

  const creatorAddress =
    type === 'STANDARD'
      ? collection.creatorAddress
      : collection.creator.publicKey;

  const isCurrentUsersCollection = areKeysEqual([
    creatorAddress,
    currentUserPublicKey,
  ]);

  const isAdminOrCreator = isCurrentUserAdmin || isCurrentUsersCollection;

  if (isCollectionModerated && !isAdminOrCreator) {
    return <CollectionWarningBlock collection={collection} />;
  }

  const isAdminOrCurrentUserOwner = isAnyTrue([
    isCurrentUsersCollection,
    isCurrentUserAdmin,
  ]);

  const reviewText = isCurrentUsersCollection
    ? 'Your collection is under review.'
    : 'This collection is under review.';

  const suspendedText = isCurrentUsersCollection
    ? 'Your collection has been removed.'
    : 'This collection has been removed.';

  const takedownText = isCurrentUsersCollection
    ? `Your collection has received a DMCA takedown notice from ${collection.moderationFrom}.`
    : `This collection has received a DMCA takedown notice from ${collection.moderationFrom}.`;

  const moderationBanner = isCollectionModerated &&
    isAdminOrCurrentUserOwner && (
      <ModerationBanner
        status={collectionModerationStatus}
        messages={{
          SUSPENDED: suspendedText,
          UNDER_REVIEW: reviewText,
          TAKEDOWN_REQUESTED: takedownText,
        }}
      />
    );

  const adminToolsModal = currentUserPublicKey && isCurrentUserAdmin && (
    <AdminToolsModal
      tools={[
        {
          id: 'change-status',
          name: 'Change Moderation Status',
          ui: (
            <ChangeStatusPane
              currentUserPublicKey={currentUserPublicKey}
              entityId={collection.id}
              moderationFrom={collection.moderationFrom}
              moderationStatus={collection.moderationStatus}
              mutation={setCollectionModerationProxy}
            />
          ),
        },
        {
          id: 'hide-collection',
          name: 'Hide Collection',
          ui: <HideCollectionPane collectionId={collection.id} />,
        },
      ]}
    />
  );

  if (type === 'STANDARD') {
    return (
      <Page
        title={collectionName}
        absolute
        headerMode={metaImage ? 'DARK' : 'LIGHT'}
        image={metaImage}
        description={description}
      >
        {moderationBanner}
        {adminToolsModal}
        {/* TODO: rename as *Layout */}
        <StandardCollectionPage {...props} />
      </Page>
    );
  } else if (type === 'DROP') {
    return null;
  } else {
    return null;
  }
}

type PageParams = {
  addressOrSlug: string;
};

export async function getStaticPaths(): Promise<
  GetStaticPathsResult<PageParams>
> {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export async function getStaticProps(
  ctx: GetStaticPropsContext<PageParams>
): Promise<GetStaticPropsResult<CollectionPageProps>> {
  if (!ctx.params) return NOT_FOUND;

  const addressOrSlugParam = ctx.params.addressOrSlug;

  const collectionPage = await serverQuery(() =>
    getCollectionPage(addressOrSlugParam)
  );

  if (collectionPage.error) {
    report(collectionPage.error);
    throw collectionPage.error;
  }

  if (!collectionPage.data) {
    return NOT_FOUND;
  }

  if (collectionPage.data.type === 'REDIRECT') {
    return {
      redirect: {
        destination: collectionPage.data.to,
        permanent: true,
      },
    };
  }

  if (collectionPage.data.type === 'STANDARD') {
    return {
      props: collectionPage.data,
      // every 1 hour
      revalidate: REVALIDATE_TIME,
    };
  }

  return {
    props: collectionPage.data,
    // every 1 mins (temporary until we have better cache-busting)
    revalidate: 60,
  };
}

type CollectionPageMintRedirect = {
  type: 'REDIRECT';
  to: string;
};

async function getCollectionPage(
  addressOrSlug: string
): Promise<CollectionPageProps | CollectionPageMintRedirect | null> {
  const collection = await getCollectionPresence(addressOrSlug);

  if (!collection || !collection.slug) {
    return null;
  }

  if (!collection.chainId) {
    return null;
  }

  if (isEditionContract(collection.contractType)) {
    const editionCollection = await getEditionCollectionBySlug({
      slug: collection.slug,
    });

    if (!editionCollection) {
      return null;
    }

    return {
      type: 'REDIRECT',
      to: getPath.mint.page(editionCollection),
    };
  } else if (isDropContract(collection.contractType)) {
    const dropCollections = await getDropCollectionBySlug({
      slug: collection.slug,
    });

    const drop = getFirstValue(dropCollections);

    if (!drop) {
      return null;
    }

    return {
      type: 'REDIRECT',
      to: getPath.mint.page(drop),
    };
  } else {
    const [standardCollection, collectionStats] = await Promise.all([
      getCollectionByContractSlug({
        contractSlug: collection.slug,
        indexedStates: [true, false],
      }),
      getCollectionStats({
        contractSlug: collection.slug,
        indexedStates: [true],
      }),
    ]);

    if (!standardCollection) {
      return null;
    }

    return {
      type: 'STANDARD',
      collection: standardCollection,
      collectionStats,
    };
  }
}
