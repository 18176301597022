import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Mono from 'components/base/Mono';
import Text from 'components/base/Text';
import { ProgressBar } from 'components/forms/DropzoneUploadProgress';

import { useCollectionArtworkCounts } from 'gql/hasura/queries/collection-artwork-counts.generated';
import { useCollectionValidity } from 'gql/hasura/queries/collection-validity.generated';
import { getAggregateCount } from 'utils/aggregate';
import {
  getFirstValue,
  isAllTrue,
  isNumber,
  isNumberType,
} from 'utils/helpers';

interface CollectionAdminPanelProps {
  contractAddress: string;
  contractSlug: string;
}

export default function CollectionAdminPanel(props: CollectionAdminPanelProps) {
  const { contractAddress, contractSlug } = props;

  const { data: collectionValidityData } = useCollectionValidity(
    { contractAddress },
    { enabled: Boolean(contractAddress) }
  );

  const collectionValidity = getFirstValue(
    collectionValidityData?.collectionValidation
  );

  const totalTokenCount = collectionValidity?.totalTokenCount;

  const { data: collectionArtworkCountsData } =
    useCollectionArtworkCounts<number>(
      { contractSlug },
      {
        enabled: Boolean(contractSlug),
        select: (data) => getAggregateCount(data.artworksTotalCount),
        refetchInterval: (data) => {
          const artworksTotalCount = data.state.data?.artworksTotalCount;

          if (
            isNumberType(artworksTotalCount) &&
            isNumberType(totalTokenCount)
          ) {
            return totalTokenCount > artworksTotalCount ? 2500 : false;
          }

          return false;
        },
      }
    );

  const hasData = isAllTrue([
    isNumber(collectionArtworkCountsData),
    isNumber(totalTokenCount),
  ]);

  const renderProgress = () => {
    const uploadProgress =
      // @ts-expect-error null-checks
      (collectionArtworkCountsData / totalTokenCount) * 100;

    return (
      <Box css={{ maxWidth: 480 }}>
        <Flex css={{ gap: '$2', marginBottom: '$5' }}>
          <Mono size={3}>
            {collectionArtworkCountsData}/{totalTokenCount}
          </Mono>
          <Text size={3}>NFTs imported</Text>
        </Flex>

        {uploadProgress < 100 && (
          <ProgressBar uploadProgress={uploadProgress} />
        )}
      </Box>
    );
  };

  if (!collectionValidity) {
    return null;
  }

  return (
    <Box
      css={{
        paddingBottom: '$6',
        '@bp2': {
          paddingY: '$8',
        },
      }}
    >
      <Heading size={4} css={{ marginBottom: '$5' }}>
        Import Admin
      </Heading>
      {hasData && renderProgress()}
    </Box>
  );
}
