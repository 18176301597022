import { match } from 'ts-pattern';
import { z } from 'zod';

import { ListingSummaryProps } from 'components/ListingSummary';

import { GenerateSignaturesQueryResult } from 'hooks/web3/use-generate-moment-signature';
import { ChainId } from 'lib/chains';
import { MIN_LIST_PRICE, UNSET_ARG } from 'lib/constants';
import { tokenIdSchema } from 'schemas/forms/generic';
import { saleStartsAtSchema } from 'schemas/forms/sell';
import { COMMON_SCHEMA_FIELDS } from 'schemas/forms/shared';
import { addressValueSchema } from 'schemas/shared';
import {
  getCuratedStorePrepareEnabled,
  getTakeRateFromCuratedStore,
  getWorldIdFromCuratedStore,
  isMomentCuratedStore,
  isWorldCuratedStore,
} from 'utils/curated-store';
import { parseEther } from 'utils/units';

import { CuratedStore } from 'types/CuratedStore';

import { marketRouterClient } from './contract-clients';
import { DO_NOT_SIMULATE_CONTRACT_WRITE } from './simulate';

export type BatchListVariables = z.input<typeof batchListSchema>;

export const mapBatchListToPrepareConfig = (options: {
  formValues: BatchListVariables;
  selectedCuratedStore: CuratedStore | null;
  signatureQuery: GenerateSignaturesQueryResult;
  chainId: ChainId;
}) => {
  const { selectedCuratedStore, formValues, chainId } = options;

  const parsedSchema = batchListSchema.safeParse(formValues);

  const isSignatureReady = getCuratedStorePrepareEnabled({
    selectedCuratedStore,
    query: options.signatureQuery,
  });

  if (!parsedSchema.success || !isSignatureReady) {
    return DO_NOT_SIMULATE_CONTRACT_WRITE;
  }

  if (
    !parsedSchema.data.shouldSetBuyPrice &&
    !parsedSchema.data.market.shouldSetReserve
  ) {
    return DO_NOT_SIMULATE_CONTRACT_WRITE;
  }

  const worldId = BigInt(getWorldIdFromCuratedStore(selectedCuratedStore));

  const takeRateInBasisPoints =
    getTakeRateFromCuratedStore(selectedCuratedStore);

  const getApprovalData = () => {
    if (isWorldCuratedStore(selectedCuratedStore)) {
      return [
        {
          worldId,
          takeRateInBasisPoints,
          worldsApprovalData: '0x' as const,
        },
      ];
    } else if (
      isMomentCuratedStore(selectedCuratedStore) &&
      options.signatureQuery.isSuccess &&
      options.signatureQuery.data
    ) {
      return options.signatureQuery.data.map((worldsApprovalData) => ({
        worldId,
        takeRateInBasisPoints,
        worldsApprovalData,
      }));
    } else {
      return [];
    }
  };

  const auctionDuration = parsedSchema.data.market.shouldSetReserve
    ? BigInt(parsedSchema.data.market.duration)
    : UNSET_ARG;

  const saleStartsAt = parsedSchema.data.saleStartsAt
    ? BigInt(parsedSchema.data.saleStartsAt)
    : UNSET_ARG;

  return marketRouterClient.getSimulateConfig('batchListFromCollection', {
    args: [
      // nftContract
      parsedSchema.data.contractAddress,
      // tokenIds
      parsedSchema.data.tokenIds.map((token) => BigInt(token)),
      // reservePrice
      parseEther(parsedSchema.data.market.reservePrice.toString()),
      // auctionDuration
      auctionDuration,
      // shouldSetBuyPrice
      parsedSchema.data.shouldSetBuyPrice,
      // buyPrice
      parseEther(parsedSchema.data.buyPrice.toString()),
      // saleStartsAt
      saleStartsAt,
      // WorldAssociation[]
      getApprovalData(),
    ],
    chainId,
  });
};

const priceSchema = z.coerce.number().nonnegative();

const NonZeroPrice = z.coerce.string().transform((price, ctx) => {
  try {
    const replaced = price.replace(',', '.');
    return priceSchema.parse(replaced);
  } catch {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Price is invalid',
    });
    return z.NEVER;
  }
});

/**
 * TODO: move into schemas/parse
 */
export const batchListSchema = z
  .object({
    contractAddress: addressValueSchema,
    tokenIds: z.array(tokenIdSchema.positive()).min(1),
    shouldSetBuyPrice: z.boolean(),
    buyPrice: NonZeroPrice,
    saleStartsAt: saleStartsAtSchema.nullable(),
    market: z.union([
      z.object({
        shouldSetReserve: z.literal(false),
        // when shouldSetReserve is false, we don't need to validate the duration
        duration: z.any(),
        reservePrice: NonZeroPrice,
      }),
      z.object({
        shouldSetReserve: z.literal(true),
        duration: COMMON_SCHEMA_FIELDS.AUCTION_DURATION,
        reservePrice: NonZeroPrice,
      }),
    ]),
  })
  .refine(
    (values) => {
      if (values.market.shouldSetReserve) {
        return values.market.reservePrice >= MIN_LIST_PRICE;
      } else {
        return true;
      }
    },
    {
      message: `Price must be minimum of ${MIN_LIST_PRICE} ETH`,
      path: ['market', 'reservePrice'],
    }
  )
  .refine(
    (values) => {
      if (values.shouldSetBuyPrice) {
        return values.buyPrice >= MIN_LIST_PRICE;
      } else {
        return true;
      }
    },
    {
      message: `Price must be minimum of ${MIN_LIST_PRICE} ETH`,
      path: ['buyPrice'],
    }
  );

export const mapBatchListToListingSummary = (options: {
  formValues: z.infer<typeof batchListSchema>;
  curatedStore: CuratedStore | null;
}): ListingSummaryProps => {
  const formValues = options.formValues;
  const curatedStore = options.curatedStore;

  return {
    auction: match(formValues.market)
      .with({ shouldSetReserve: true }, () => ({
        reservePrice: formValues.market.reservePrice,
        durationInSeconds: formValues.market.duration,
      }))
      .otherwise(() => null),
    buyPrice: formValues.shouldSetBuyPrice ? formValues.buyPrice : null,
    scheduledAt: formValues.saleStartsAt || 0,
    curatedStore,
  };
};
