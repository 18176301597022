import Spinner from 'components/Spinner';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Text from 'components/base/Text';

export function LoadingDescriptor(props: { label: string; sublabel: string }) {
  return (
    <Flex
      css={{
        gap: '$3',
        alignItems: 'center',
      }}
    >
      <Spinner size={2} />
      <Box>
        <Text
          size={1}
          weight="medium"
          lineHeight={0}
          css={{ marginBottom: '$1' }}
        >
          {props.label}
        </Text>
        <Text size={0} lineHeight={0} color="dim">
          {props.sublabel}
        </Text>
      </Box>
    </Flex>
  );
}
