import { styled } from '@f8n-frontend/stitches';
import React, { ReactElement } from 'react';

import {
  getWorldFromCuratedStore,
  isMomentCuratedStore,
} from 'utils/curated-store';
import { formatDurationFromSeconds, getScheduledTime } from 'utils/dates/dates';
import { formatETHWithSuffix } from 'utils/formatters';
import { isNumberType } from 'utils/helpers';

import { CuratedStore } from 'types/CuratedStore';

import { MomentTag } from './MomentTag';
import Badge from './base/Badge';
import Flex from './base/Flex';
import Text from './base/Text';

type AuctionSummary = {
  reservePrice: number;
  durationInSeconds: number;
};

export type ListingSummaryProps = {
  auction: AuctionSummary | null;
  buyPrice: number | null;
  curatedStore: CuratedStore | null;
  scheduledAt: number;
};

export function ListingSummary(props: ListingSummaryProps) {
  return (
    <div style={{ maxWidth: 420, width: '100%' }}>
      {isNumberType(props.buyPrice) && (
        <ListingSummaryRow
          label="Buy Price"
          value={formatETHWithSuffix(props.buyPrice)}
        />
      )}

      {props.auction && (
        <>
          <ListingSummaryRow
            label="Reserve Price"
            value={formatETHWithSuffix(props.auction.reservePrice)}
          />
          <ListingSummaryRow
            label="Auction Duration"
            value={formatDurationFromSeconds(props.auction.durationInSeconds)}
          />
        </>
      )}

      <ListingSummaryRow
        label="Available for sale"
        value={getScheduledTime(props.scheduledAt)}
      />

      {isMomentCuratedStore(props.curatedStore) && (
        <>
          <ListingSummaryRow
            label="Listing in"
            value={
              <MomentTag
                world={getWorldFromCuratedStore(props.curatedStore)}
                moment={props.curatedStore.moment}
              />
            }
          />

          <ListingSummaryRow
            label="Curator fee"
            value={
              <Badge>
                {props.curatedStore.moment.takeRateInBasisPoints / 100}%
              </Badge>
            }
          />
        </>
      )}
    </div>
  );
}

function ListingSummaryRow(props: {
  label: string;
  value: ReactElement | string;
}) {
  return (
    <Flex
      css={{
        paddingY: '$4',
        borderTop: 'solid 1px $black5',
      }}
    >
      <SummaryText
        color="dim"
        css={{
          flex: 2,
        }}
      >
        {props.label}
      </SummaryText>
      <SummaryText
        css={{
          flex: 3,
        }}
      >
        {props.value}
      </SummaryText>
    </Flex>
  );
}

const SummaryText = styled(Text);

SummaryText.defaultProps = {
  size: 1,
  weight: 'medium',
};
