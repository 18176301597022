import { PlusIcon, TagIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { useEffect } from 'react';

import FarcasterHead from 'components/FarcasterHead';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Button, { ButtonVariants } from 'components/base/Button';
import ButtonWithCount from 'components/base/ButtonWithCount';
import CollectionLogo from 'components/base/CollectionLogo';
import Flex from 'components/base/Flex';
import Skeleton from 'components/base/Skeleton';
import CollectionAdminPanel from 'components/collections/CollectionAdminPanel';
import CollectionArtworks from 'components/collections/CollectionArtworks';
import CollectionDescription from 'components/collections/CollectionDescription';
import CollectionHeroContainer from 'components/collections/CollectionHeroContainer';
import CollectionMenu from 'components/collections/CollectionMenu';
import CollectionNotAdded from 'components/collections/CollectionNotAdded';
import CollectionSalesHistory from 'components/collections/CollectionSalesHistory';
import CollectionTitle from 'components/collections/CollectionTitle';
import ContractPill from 'components/collections/ContractPill';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import { BatchListModal } from 'components/modals/BatchListModal';
import CollectionOwnersModal from 'components/modals/CollectionOwnersModal';
import EditCollectionModal from 'components/modals/EditCollectionModal';
import ReportModal from 'components/modals/ReportModal';
import ShareModal from 'components/modals/v2/ShareModal';
import { DeepLinkTabs } from 'components/tabs/Tabs';
import UserTag from 'components/users/UserTag';
import {
  hasPublicKey,
  hasToken,
  isAdmin,
  isPending,
} from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { ApiListableNftFragment } from 'gql/api/api-fragments.generated';
import { useInfiniteCollectionListableNfts } from 'gql/api/queries/collection-listable-nfts.generated';
import { CollectionFragmentExtended } from 'gql/hasura/hasura-fragments.generated';
import { useCollectionArtworkCounts } from 'gql/hasura/queries/collection-artwork-counts.generated';
import { CollectionStats } from 'gql/hasura/queries/collection-stats.generated';
import useTrackView from 'hooks/analytics/use-track-view';
import useCollectionByContractSlug from 'hooks/queries/hasura/collections/use-collection-by-contract-slug';
import useCollectionTabs, {
  CollectionTabValue,
} from 'hooks/use-collection-tabs';
import useModal from 'hooks/use-modal';
import useReferral from 'hooks/web3/use-referral';
import { ChainId } from 'lib/chains';
import { MAX_BATCH_LIST_COUNT } from 'lib/constants';
import { getChainAnalyticsSummary } from 'utils/analytics';
import { getBlockExplorerByChainId } from 'utils/block-explorer';
import { getChainIdOrDefault } from 'utils/chains';
import {
  buildCollectionPath,
  buildCollectionSymbol,
  isSharedContract,
} from 'utils/collections';
import { isAnyTrue } from 'utils/helpers';
import { optimizeAsset } from 'utils/imgix';
import { apiPaginator, extractNestedPaginatedData } from 'utils/react-query';
import { getPath } from 'utils/router';
import { areKeysEqual } from 'utils/users';

const COLLECTION_BUTTON_SIZE: ButtonVariants['size'] = {
  '@initial': 0,
  '@bp2': 1,
};

export type StandardCollectionPageProps = {
  type: 'STANDARD';
  collection: CollectionFragmentExtended;
  collectionStats: CollectionStats;
};

export function StandardCollectionPage(props: StandardCollectionPageProps) {
  const { collectionStats } = props;

  const cachedCollection = props.collection;

  const auth = useAuth();
  const isCurrentUserAdmin = isAdmin(auth);
  const isUserDataLoading = isPending(auth);
  const trackView = useTrackView();

  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : '';

  const indexedStates = [true, false];

  const { data: collection = cachedCollection } = useCollectionByContractSlug(
    { contractSlug: cachedCollection.slug, indexedStates },
    {
      refetchOnWindowFocus: false,
      initialData: { items: [cachedCollection] },
    }
  );

  const contractSlug = collection.slug as string;

  // Check to see if ref query param is set and create cookie
  useReferral({ contractAddress: collection.contractAddress });

  const chainId = getChainIdOrDefault(collection);
  const explorer = getBlockExplorerByChainId(chainId);

  useEffect(() => {
    trackView({
      name: 'viewed_collection',
      chain: getChainAnalyticsSummary(chainId),
      collectionType: 'standard',
      contractAddress: collection.contractAddress,
      slug: collection.slug as string,
    });
  }, []);

  const isCurrentUsersCollection = areKeysEqual([
    collection.creatorAddress,
    currentUserPublicKey,
  ]);

  const modal = useModal();

  const { activeTabValue, tabs } = useCollectionTabs({
    collection,
    isCurrentUserAdmin,
  });

  const hasNoCoverImage = !collection.coverImageUrl;

  const { data: artworkCounts } = useCollectionArtworkCounts(
    { contractSlug },
    { refetchOnWindowFocus: false, enabled: Boolean(contractSlug) }
  );

  const coverImageUrl = collection.coverImageUrl
    ? optimizeAsset(collection.coverImageUrl, {
        'max-h': 760,
        'max-w': 2000,
        fit: 'crop',
      })
    : undefined;

  const isAdminOrCurrentUserOwner = isAnyTrue([
    isCurrentUsersCollection,
    isCurrentUserAdmin,
  ]);

  const artworksTotalCount =
    artworkCounts?.artworksTotalCount?.aggregate?.count;

  const collectionListableNfts = useInfiniteCollectionListableNfts(
    {
      collection: {
        chainId: collection.chainId as ChainId,
        contractAddress: collection.contractAddress,
      },
      perPage: MAX_BATCH_LIST_COUNT,
    },
    {
      enabled: isCurrentUsersCollection && hasToken(auth),
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        if (!lastPage.collectionListableNfts) return;
        return apiPaginator.getNextPageParam(lastPage.collectionListableNfts);
      },
      initialPageParam: apiPaginator.initialPageParam,
    }
  );

  if (!collection) {
    return <CollectionNotAdded isLoading={isUserDataLoading} />;
  }

  const unstyledCollection =
    !collection.coverImageUrl ||
    !collection.collectionImageUrl ||
    !collection.name;

  const isEmptyCollection = artworksTotalCount === 0;
  const collectionPath = buildCollectionPath(collection);

  const isSharedCollection = isSharedContract(collection.contractType);
  const showCreator = Boolean(!isSharedCollection && collection.creator);

  const userTag =
    showCreator && !isCurrentUsersCollection ? (
      <UserTag
        nameVariant="prefer-username"
        size={COLLECTION_BUTTON_SIZE}
        user={collection.creator}
        variant={hasNoCoverImage ? 'outline' : 'blur'}
      />
    ) : null;

  const editCollectionButton = isAdminOrCurrentUserOwner ? (
    <EditCollectionModal
      contractAddress={collection.contractAddress}
      collectionSaleType={null}
      variant={hasNoCoverImage ? 'outline' : 'blur'}
      size={COLLECTION_BUTTON_SIZE}
    />
  ) : null;

  const skeletonButtonCss = hasNoCoverImage
    ? { backgroundColor: '$black10' }
    : { backgroundColor: '$white20', backdropFilter: 'blur(10px)' };

  const { items, totalItemsCount: maxNftCount } = extractNestedPaginatedData(
    collectionListableNfts.data,
    'collectionListableNfts'
  );

  const renderCollectionActions = () => {
    if (isUserDataLoading) {
      return (
        <Flex
          css={{
            alignItems: 'center',
            gap: '$2',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Skeleton.Button
            css={{
              ...skeletonButtonCss,
              width: 120,
              '@bp1': {
                width: 150,
              },
            }}
            variant="fill"
            size={COLLECTION_BUTTON_SIZE}
          />
          <Skeleton.Button
            css={{
              ...skeletonButtonCss,
              width: 120,
              '@bp1': {
                width: 150,
              },
            }}
            variant="fill"
            size={COLLECTION_BUTTON_SIZE}
          />
          <Skeleton.Button
            css={{
              ...skeletonButtonCss,
              width: 122,
            }}
            variant="fill"
            size={COLLECTION_BUTTON_SIZE}
          />
        </Flex>
      );
    } else {
      return (
        <Flex
          css={{
            alignItems: 'center',
            gap: '$2',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {userTag}
          {editCollectionButton}

          {isCurrentUsersCollection && (
            <>
              <NextLink
                passHref
                href={getPath.create.mintToCollection({
                  contractAddress: collection.contractAddress,
                  chainId,
                })}
              >
                <Button
                  as="a"
                  variant={hasNoCoverImage ? 'primary' : 'raised'}
                  size={COLLECTION_BUTTON_SIZE}
                  icon
                >
                  <PlusIcon />
                  <span>Mint NFT</span>
                </Button>
              </NextLink>
              {collectionListableNfts.isLoading && (
                <Skeleton.Button
                  css={{
                    ...skeletonButtonCss,
                    width: 122,
                  }}
                  variant="fill"
                  size={COLLECTION_BUTTON_SIZE}
                />
              )}

              {collectionListableNfts.data && (
                <>
                  <BatchListModal
                    refetchQuery={collectionListableNfts.refetch}
                    maxNftCount={maxNftCount}
                    // Type cast is needed because the API could return null for each collectionListableNfts field
                    // In reality, this should never happen, because the field is authorized, and we guard against the auth state elsewhere
                    nfts={items as ApiListableNftFragment[]}
                    fetchMoreComponent={
                      <InfiniteScrollButton
                        handleNextPage={collectionListableNfts.fetchNextPage}
                        hasNextPage={collectionListableNfts.hasNextPage}
                        isFetching={collectionListableNfts.isFetching}
                      />
                    }
                  />

                  <ButtonWithCount.Root
                    css={{
                      display: maxNftCount > 0 ? 'inherit' : 'none',
                    }}
                    onClick={() => {
                      modal.setModal({
                        type: 'BATCH_LIST',
                        contractAddress: collection.contractAddress,
                        chainId: getChainIdOrDefault(collection),
                      });
                    }}
                    variant={hasNoCoverImage ? 'primary' : 'raised'}
                    size={COLLECTION_BUTTON_SIZE}
                    disabled={maxNftCount === 0}
                  >
                    <TagIcon />
                    <Box
                      as="span"
                      css={{
                        marginLeft: 6,
                        '@bp0': {
                          marginLeft: '$2',
                        },
                      }}
                    >
                      List
                    </Box>
                    <ButtonWithCount.Count>{maxNftCount}</ButtonWithCount.Count>
                  </ButtonWithCount.Root>
                </>
              )}
            </>
          )}
        </Flex>
      );
    }
  };

  const renderTab = (tabValue: CollectionTabValue) => {
    switch (tabValue) {
      case 'DESCRIPTION':
        return (
          <CollectionDescription>
            {collection.description || ''}
          </CollectionDescription>
        );
      case 'ACTIVITY':
        return (
          <CollectionSalesHistory
            contractAddress={collection.contractAddress}
          />
        );
      case 'ARTWORKS':
        return (
          <CollectionArtworks
            collectionChainId={chainId}
            collection={collection}
            burnedTokensCount={null}
            mintedTokensCount={null}
            isOwnerOnCollection={isCurrentUsersCollection}
            isOwnerOrAdmin={isAdminOrCurrentUserOwner}
            unstyledCollection={unstyledCollection}
            isEmptyCollection={isEmptyCollection}
          />
        );
      case 'ADMIN':
        return (
          <CollectionAdminPanel
            contractAddress={collection.contractAddress}
            contractSlug={contractSlug}
          />
        );
    }
  };

  return (
    <>
      <FarcasterHead
        collectionName={collection.name}
        contractAddress={collection.contractAddress}
        creatorAddress={collection.creator.publicKey}
      />
      <CollectionOwnersModal
        contractSlug={contractSlug}
        currentUserPublicKey={currentUserPublicKey}
      />
      <ReportModal
        publicKey={currentUserPublicKey}
        reportedPublicKey={collection.creatorAddress}
        pageType="Collection"
      />
      <>
        <CollectionHeroContainer
          contractSlug={contractSlug}
          coverImage={coverImageUrl}
          collectionStats={collectionStats}
          collection={collection}
          creator={collection.creator}
          currentUserPublicKey={currentUserPublicKey}
          isCurrentUserAdmin={isCurrentUserAdmin}
          isOwner={isCurrentUsersCollection}
          isAdminOrCurrentUserOwner={isAdminOrCurrentUserOwner}
        >
          <CollectionHeroInner>
            <CollectionHeroDetails>
              {collection.collectionImageUrl && (
                <CollectionLogo
                  alt={collection.name}
                  imageUrl={collection.collectionImageUrl}
                  size={{ '@initial': 8, '@bp1': 9 }}
                  variant="blur"
                />
              )}
              <ContractPillContainer>
                <ContractPill
                  frosted={!hasNoCoverImage}
                  contract={buildCollectionSymbol(collection)}
                  href={explorer.address.getUrl({
                    address: collection.contractAddress,
                  })}
                />
              </ContractPillContainer>
              <CollectionTitle
                color={hasNoCoverImage ? 'dark' : 'light'}
                css={{
                  marginBottom: '$6',
                  textAlign: 'center',
                  '@bp2': { marginBottom: '$7', textAlign: 'left' },
                }}
                size={{ '@bpxs': 5, '@initial': 6, '@bp2': 9 }}
              >
                {collection.name}
              </CollectionTitle>

              <CollectionActions>{renderCollectionActions()}</CollectionActions>
            </CollectionHeroDetails>
          </CollectionHeroInner>
        </CollectionHeroContainer>
        <Body id="nfts" css={{ paddingTop: '$11' }}>
          <Flex
            css={{
              justifyContent: 'center',
              marginBottom: '$7',
              display: 'flex',
              '@bp2': {
                display: 'none',
              },
            }}
          >
            <Box css={{ marginRight: '$3' }}>
              <CollectionMenu
                chainId={chainId}
                isCurrentUserAdmin={isCurrentUserAdmin}
                isOwner={isCurrentUsersCollection}
                collectionPath={collectionPath}
                creatorAddress={collection.creatorAddress}
                contractAddress={collection.contractAddress}
              />
            </Box>
            <ShareModal
              shareType="collection"
              collection={collection}
              creator={collection.creator}
              chainId={chainId}
            />
          </Flex>

          <DeepLinkTabs activeValue={activeTabValue} tabs={tabs} />

          {renderTab(activeTabValue)}
        </Body>
      </>
    </>
  );
}

const CollectionHeroInner = styled('div', {
  display: 'flex',
  paddingBottom: '$7',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@bp2': {
    paddingY: 0,
    flexDirection: 'row',
  },
});

const CollectionHeroDetails = styled('div', {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '@bp2': {
    alignItems: 'flex-start',
  },
});

const ContractPillContainer = styled('div', {
  marginY: '$6',
  '@bp2': {
    marginTop: '$8',
    marginBottom: '$7',
  },
});

const CollectionActions = styled('div', {
  gap: '$2',
  marginTop: '$2',
});
