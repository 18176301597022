import { hoursToSeconds } from 'date-fns';
import { useToggle } from 'react-use';

import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Field from 'components/base/Field';
import Grid from 'components/base/Grid';
import { SelectProps } from 'components/base/Select';

import DurationPicker from './DurationPicker';

type DurationFieldsProps = {
  activeDurationInSeconds: number | null;
  onChange: (durationInSeconds: number) => void;
  size?: SelectProps['size'];
};

const AUCTION_DURATION_OPTIONS = [
  {
    label: '4h',
    durationInSeconds: hoursToSeconds(4),
  },
  {
    label: '24h',
    durationInSeconds: hoursToSeconds(24),
  },
  {
    label: '7d',
    durationInSeconds: hoursToSeconds(7 * 24),
  },
] as const;

export function DurationFields(props: DurationFieldsProps) {
  const { activeDurationInSeconds, onChange, size = 1 } = props;

  const [isDurationPickerOpen, toggleDurationPicker] = useToggle(false);

  return (
    <Field
      htmlFor=""
      label="Auction duration"
      tooltip="Length of time for auction once bid is placed"
      required
    >
      <Grid
        css={{
          gap: '$2',
          gridAutoFlow: 'column',
        }}
      >
        {AUCTION_DURATION_OPTIONS.map((option) => {
          return (
            <StandardAuctionDurationOptionButton
              key={option.durationInSeconds}
              activeDurationInSeconds={activeDurationInSeconds}
              option={option}
              onSelect={(durationInSeconds) => {
                props.onChange(durationInSeconds);
                toggleDurationPicker(false);
              }}
              isDurationPickerOpen={isDurationPickerOpen}
            />
          );
        })}
        <Button
          size={0}
          type="button"
          variant={isDurationPickerOpen ? 'primary' : 'outline'}
          onClick={() => {
            toggleDurationPicker();
          }}
        >
          Custom
        </Button>
      </Grid>
      {isDurationPickerOpen && (
        <Box css={{ paddingTop: '$4' }}>
          <DurationPicker
            defaultDurationInSeconds={activeDurationInSeconds || undefined}
            onValidChange={onChange}
            size={size}
          />
        </Box>
      )}
    </Field>
  );
}

function StandardAuctionDurationOptionButton(props: {
  activeDurationInSeconds: number | null;
  isDurationPickerOpen: boolean;
  onSelect: (durationInSeconds: number) => void;
  option: (typeof AUCTION_DURATION_OPTIONS)[number];
}) {
  const { activeDurationInSeconds } = props;
  const { durationInSeconds, label } = props.option;
  const isActive = activeDurationInSeconds === durationInSeconds;

  return (
    <Button
      size={0}
      type="button"
      variant={
        isActive && !props.isDurationPickerOpen ? 'primary' : 'secondary'
      }
      onClick={() => {
        props.onSelect(props.option.durationInSeconds);
      }}
    >
      {label}
    </Button>
  );
}
