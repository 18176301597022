import { toPx } from '@f8n/tokens';
import { darkMode, styled } from '@f8n-frontend/stitches';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import { VariantProps } from '@stitches/react';
import React, { forwardRef } from 'react';

const sizeScale = [16, 24, 32, 48] as const;
const gapScale = [3, 4, 6, 8] as const;

const SIZING_MULTIPLIER = 1.75;

const Circle = styled('div', {
  borderRadius: '$round',
  willChange: 'transform',
  transition:
    'transform $1 $ease, background-color $1 $ease, color $1 $ease, outline $1 $ease',
});

const Root = styled(TogglePrimitive.Root, {
  paddingY: 0,
  cursor: 'pointer',
  appearance: 'none',

  borderRadius: '$round',
  boxSizing: 'border-box',

  willChange: 'transform',
  transition:
    'transform $1 $ease, background-color $1 $ease, color $1 $ease, outline $1 $ease',

  border: 'none',
  padding: 'unset',
  height: 'unset',
  outline: 'none',

  '&:disabled': {
    cursor: 'not-allowed',
  },

  variants: {
    size: {
      0: {
        paddingX: toPx(gapScale[0]),
        height: toPx(sizeScale[0]),
        width: toPx(sizeScale[0] * SIZING_MULTIPLIER),

        '&:focus-visible': {
          outline: '3px solid $black30',
        },

        [`${Circle}`]: {
          width: toPx(sizeScale[0] - gapScale[0] * SIZING_MULTIPLIER), // 3 on each side
          height: toPx(sizeScale[0] - gapScale[0] * SIZING_MULTIPLIER), // 3 on each side
        },
      },
      1: {
        paddingX: toPx(gapScale[1]),
        height: toPx(sizeScale[1]),
        width: toPx(sizeScale[1] * SIZING_MULTIPLIER),

        '&:focus-visible': {
          outline: '4px solid $black30',
        },

        [`${Circle}`]: {
          width: toPx(sizeScale[1] - gapScale[1] * SIZING_MULTIPLIER), // 4 on each side
          height: toPx(sizeScale[1] - gapScale[1] * SIZING_MULTIPLIER), // 4 on each side
        },
      },
      2: {
        paddingX: toPx(gapScale[2]),
        height: toPx(sizeScale[2]),
        width: toPx(sizeScale[2] * SIZING_MULTIPLIER),

        '&:focus-visible': {
          outline: '6px solid $black30',
        },

        [`${Circle}`]: {
          width: toPx(sizeScale[2] - gapScale[2] * SIZING_MULTIPLIER), // 6 on each side
          height: toPx(sizeScale[2] - gapScale[2] * SIZING_MULTIPLIER), // 6 on each side
        },
      },
      3: {
        paddingX: toPx(gapScale[3]),
        height: toPx(sizeScale[3]),
        width: toPx(sizeScale[3] * SIZING_MULTIPLIER),

        '&:focus-visible': {
          outline: '8px solid $black30',
        },

        [`${Circle}`]: {
          width: toPx(sizeScale[3] - gapScale[3] * SIZING_MULTIPLIER), // 8 on each side
          height: toPx(sizeScale[3] - gapScale[3] * SIZING_MULTIPLIER), // 8 on each side
        },
      },
    },
    variant: {
      primary: {
        '&:active': {
          [`${Circle}`]: {
            transform: 'scaleX(1.2)',
          },
        },

        [`${Circle}`]: {
          boxShadow: '$soft0',
          background: '$white100',

          [darkMode]: {
            backgroundColor: '$black100',
          },
        },

        ['&[data-state="on"]']: {
          backgroundColor: '$black100',

          [darkMode]: {
            backgroundColor: '$blue3',
          },

          '&:active': {
            [`${Circle}`]: {
              transform: `translateX(100%) scaleX(1.2)`,
            },
          },
          [`${Circle}`]: {
            transformOrigin: 'right',
            transform: 'translateX(100%)',
          },
          '@hover': {
            '&:hover': {
              backgroundColor: '$black80',
              [darkMode]: {
                backgroundColor: '$blue3',
              },
            },
          },
        },

        ['&[data-state="off"]']: {
          backgroundColor: '$black10',
          [darkMode]: {
            backgroundColor: '$black25',
          },
          [`${Circle}`]: {
            transformOrigin: 'left',
          },
          '@hover': {
            '&:hover': {
              backgroundColor: '$black15',
              [darkMode]: {
                backgroundColor: '$black30',
              },
            },
          },
        },
      },
    },
  },
  defaultVariants: {
    size: 1,
    variant: 'primary',
  },
});

type ToggleVariants = VariantProps<typeof Root>;

type ToggleProps = TogglePrimitive.ToggleProps & ToggleVariants;

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  function Toggle(props, ref) {
    return (
      <Root {...props}>
        <input
          type="checkbox"
          ref={ref}
          style={{
            visibility: 'hidden',
            position: 'absolute',
            pointerEvents: 'none',
            opacity: 0,
          }}
          tabIndex={-1}
        />
        <Circle />
      </Root>
    );
  }
);

export default Toggle;
